import { of, catchError, from } from "rxjs";
import { concatMap, mergeMap } from "rxjs/operators";
import * as cropActions from "../actions/cropActions";
import * as modalActions from "../actions/modalActions";
import { ofType, combineEpics } from "redux-observable";
import { LABEL, MESSAGE } from "src/constants/commonConstants";
import { ROUTECONSTANTS } from "src/constants/routeConstants";

const CROP = "Crop";

export const fetchCropsEpic = (
  action$: any,
  state$: any,
  { cropService }: any
) => {
  return action$.pipe(
    ofType(cropActions.FETCH_CROP_LIST),
    mergeMap(() => {
      return from(cropService.fetchCropList()).pipe(
        concatMap((response: any) => {
          console.log("Fetch crop list response", response);
          let actions = [];
          actions.push(cropActions.storeCropList(response.data.crops));
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const addCropEpic = (
  action$: any,
  state$: any,
  { cropService }: any
) => {
  return action$.pipe(
    ofType(cropActions.ADD_CROP),
    mergeMap(({ payload }) => {
      return from(cropService.addCrop({ ...payload })).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.ADDED_SUCCESSFULLY(CROP),
              detailMessage: { type: "Crop Name", name: payload.cropName },
              subMessage: MESSAGE.ADDED_SUCCESSFULLY_SUB_MESSAGE(CROP),
              okButtonText: LABEL.CONTINUE,
              handleConfirmationOk: () =>
                (window.location.href = ROUTECONSTANTS.CROP_MANAGEMENT_CROP),
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];

          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes(
              "already exists with same language"
            )
          ) {
            actions.push(
              modalActions.open({
                message: MESSAGE.ALREADY_EXIST(CROP),
                subMessage: MESSAGE.ALREADY_EXIST_SUB_MESSAGE(CROP),
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message || "",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const putCropEpic = (
  action$: any,
  state$: any,
  { cropService }: any
) => {
  return action$.pipe(
    ofType(cropActions.PUT_CROP),
    mergeMap(({ payload }) => {
      console.log("payload", payload);
      return from(cropService.editCrop({ ...payload })).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.UPDATED_SUCCESSFULLY(CROP),
              detailMessage: { type: "Crop Name", name: payload.cropName },
              subMessage: MESSAGE.UPDATED_SUCCESSFULLY_SUB_MESSAGE(CROP),
              okButtonText: LABEL.CONTINUE,
              handleConfirmationOk: () =>
                (window.location.href = ROUTECONSTANTS.CROP_MANAGEMENT_CROP),
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];

          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes(
              "already exists with same language"
            )
          ) {
            actions.push(
              modalActions.open({
                message: MESSAGE.ALREADY_EXIST(CROP),
                subMessage: MESSAGE.ALREADY_EXIST_SUB_MESSAGE(CROP),
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message || "",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const deleteCropEpic = (
  action$: any,
  state$: any,
  { cropService }: any
) => {
  return action$.pipe(
    ofType(cropActions.DELETE_CROP),
    mergeMap(({ payload }) => {
      return from(cropService.deleteCrop({ cropId: payload })).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(cropActions.fetchCrops());
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.ACTIVE_DELETE(CROP),
              subMessage: MESSAGE.ACTIVE_DELETE_SUB_MESSAGE,
              okButtonText: LABEL.CLOSE,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const updateCropEpic = (
  action$: any,
  state$: any,
  { cropService }: any
) => {
  return action$.pipe(
    ofType(cropActions.UPDATE_CROP),
    mergeMap(({ payload }) => {
      return from(cropService.updateCrop(payload)).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(cropActions.fetchCrops());
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err?.response?.data?.message);
          let actions = [];
          actions.push(
            modalActions.open({
              message: err?.response?.data?.message || "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export default combineEpics(
  addCropEpic,
  putCropEpic,
  fetchCropsEpic,
  deleteCropEpic,
  updateCropEpic
);
