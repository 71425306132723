import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import React from "react";
import Error from "./components/common/error";
import { ROUTECONSTANTS } from "../src/constants/routeConstants";
import CropComponent from "./components/cropManagement/crop/index";
import AddNewCrop from "./components/cropManagement/crop/AddNewCrop";
import CropPlanComponent from "./components/cropPlanManagement/index";
import VarietyComponent from "./components/cropManagement/variety/index";
import NewQueriesComponent from "./components/queryManagement/newQueries";
import AddNewVariety from "./components/cropManagement/variety/AddNewVariety";
import ActiveQueriesComponent from "./components/queryManagement/activeQueries";
import ProtectedRoute from "./utilis/ProtectedRoute";
import CropPlanPreview from "./components/cropPlanManagement/CropPlanPreview";
import ApprovedIntents from "./components/farmerIntent/ApprovedIntents";
import OpenIntents from "./components/farmerIntent/OpenIntents";
import Farmers from "./components/farmerIntent/Farmers";
import ASINs from "./components/farmerIntent/ASINs";
import FarmerManagement from "./components/farmerIntent/FarmerIntentManagement";

const RootRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path={ROUTECONSTANTS.ROOT}
          element={
            <ProtectedRoute
              outlet={<NewQueriesComponent />}
              path={ROUTECONSTANTS.ROOT}
            />
          }
        />
        <Route
          path={ROUTECONSTANTS.CROP_MANAGEMENT_CROP}
          element={
            <ProtectedRoute
              outlet={<CropComponent />}
              path={ROUTECONSTANTS.CROP_MANAGEMENT_CROP}
            />
          }
        />
        <Route path={ROUTECONSTANTS.CROP_PLAN_MANAGEMENT}>
          <Route
            index
            element={
              <ProtectedRoute
                outlet={<CropPlanComponent />}
                path={ROUTECONSTANTS.CROP_PLAN_MANAGEMENT}
              />
            }
          />
          <Route
            path={ROUTECONSTANTS.CROP_PLAN_MANAGEMENT_PREVIEW}
            element={
              <ProtectedRoute
                outlet={<CropPlanPreview />}
                path={ROUTECONSTANTS.CROP_PLAN_MANAGEMENT_PREVIEW}
              />
            }
          />
        </Route>
        <Route
          path={ROUTECONSTANTS.CROP_MANAGEMENT_CROP_ADD}
          element={
            <ProtectedRoute
              outlet={<AddNewCrop />}
              path={ROUTECONSTANTS.CROP_MANAGEMENT_CROP_ADD}
            />
          }
        />
        <Route
          path={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY}
          element={
            <ProtectedRoute
              outlet={<VarietyComponent />}
              path={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY}
            />
          }
        />
        <Route
          path={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY_ADD}
          element={
            <ProtectedRoute
              outlet={<AddNewVariety />}
              path={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY_ADD}
            />
          }
        />
        <Route
          path={ROUTECONSTANTS.QUERY_MANAGEMENT_NEW_QUERIES}
          element={
            <ProtectedRoute
              outlet={<NewQueriesComponent />}
              path={ROUTECONSTANTS.QUERY_MANAGEMENT_NEW_QUERIES}
            />
          }
        />
        <Route
          path={ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES}
          element={
            <ProtectedRoute
              outlet={<ActiveQueriesComponent />}
              path={ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES}
            />
          }
        />
        <Route path={ROUTECONSTANTS.ANY} element={<Error />} />

        <Route
          path={ROUTECONSTANTS.FARMER_INTENT}
          element={
            <ProtectedRoute
              outlet={<FarmerManagement />}
              path={ROUTECONSTANTS.FARMER_INTENT}
            />
          }
        >
          <Route
            index
            path={ROUTECONSTANTS.FARMER_INTENT_FARMERS}
            element={
              <ProtectedRoute
                outlet={<Farmers />}
                path={ROUTECONSTANTS.FARMER_INTENT_FARMERS}
              />
            }
          />
          <Route
            path={ROUTECONSTANTS.FARMER_INTENT_ASINS}
            element={
              <ProtectedRoute
                outlet={<ASINs />}
                path={ROUTECONSTANTS.FARMER_INTENT_ASINS}
              />
            }
          />
          <Route
            path={ROUTECONSTANTS.FARMER_INTENT_OPEN_INTENTS}
            element={
              <ProtectedRoute
                outlet={<OpenIntents />}
                path={ROUTECONSTANTS.FARMER_INTENT_OPEN_INTENTS}
              />
            }
          />
          <Route
            path={ROUTECONSTANTS.FARMER_INTENT_APPROVED_INTENTS}
            element={
              <ProtectedRoute
                outlet={<ApprovedIntents />}
                path={ROUTECONSTANTS.FARMER_INTENT_APPROVED_INTENTS}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default RootRouter;
