import {
  KatColumn,
  KatDropdown,
  KatInput,
  KatRow,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableFooter,
  KatTableHead,
  KatTableRow,
  KatGridItem,
  KatGrid,
  KatButton
} from "@amzn/katal-react";
import React from "react";

import { LABEL, STATUS_ASIN_ALLOWLISTING } from "src/constants/commonConstants";

const MockData = [
  {
    asinName: "123",
    asinId : "5234",
    status: "active",
  },
  {
    asinName: "112323",
    asinId : "523",
    status: "active",
  },
  {
    asinName: "141223",
    asinId : "523",
    status: "active",
  },
  {
    asinName: "12423",
    asinId : "234",
    status: "Not active",
  },
  {
    asinName: "122433",
    asinId : "54",
    status: "active",
  },
  {
    asinName: "123",
    asinId : "54",
    status: "active",
  },
];

export default function ASINs() {
  const addASINHandler = () => {
    // logic for adding asin
  };

  return (
    <>
      <form>
        <KatGrid
          cols-l="1"
          cols-m="1"
          cols-s="1"
          cols-xl="3"
          cols-xxl="4"
          density="low"
          variant="item"
          className="ps-3 pe-5"
          data-testid="asin-form"
        >
          <KatGridItem data-testid="asin-name-input">
            <KatInput
              label={LABEL.ASIN_NAME}
              type="text"
              placeholder={LABEL.ENTER_NAME}
              required
            />
          </KatGridItem>
          <KatGridItem data-testid="media-link-input">
            <KatInput
              type="text"
              min={0}
              inputmode="numeric"
              label={LABEL.MEDIA_LINK}
              placeholder={LABEL.ENTER_MEDIA_LINK}
              required
            />
          </KatGridItem>
          <KatGridItem className="flex text-center align-items-center gap-2" data-testid="add-asin-button">
            <KatButton
              type="submit"
              onClick={() => addASINHandler()}
              label={LABEL.ADD_ASIN}
              variant="primary"
              className="mt-3 w-15 flex justify-items-center"
            />
          </KatGridItem>
        </KatGrid>
      </form>

      <KatInput
        className="mt-3 m-search"
        label={LABEL.SEARCH_ASINS}
        type="text"
        placeholder={LABEL.ASIN_NAME_OR_ID}
        data-testid="search-input"
      />

      <KatRow className="align-items-end mt-4 overflow-visible" data-testid="asin-table">
        <KatColumn sm={12}>
          <KatTable>
            <KatTableHead>
              <KatTableRow>
                <KatTableCell>{LABEL.ASIN_NAME}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.ASIN_ID}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.STATUS}</KatTableCell>
              </KatTableRow>
            </KatTableHead>
            <KatTableBody>
              {MockData.map((item, index) => (
                <KatTableRow key={index} data-testid={`asin-row-${index}`}>
                  <KatTableCell>{item.asinName}</KatTableCell>
                  <KatTableCell className="text-center">{item.asinId}</KatTableCell>
                  <KatTableCell>
                    <KatDropdown
                      placeholder="Select Status"
                      options={STATUS_ASIN_ALLOWLISTING}
                      onChange={(event) => {}}
                    />
                  </KatTableCell>
                </KatTableRow>
              ))}
            </KatTableBody>
          </KatTable>
          <KatTableFooter className="p-2 h-100">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3">{MockData.length} Results</div>
            </section>
          </KatTableFooter>
        </KatColumn>
      </KatRow>
    </>
  );
}
