import {
  KatButton,
  KatColumn,
  KatGrid,
  KatGridItem,
  KatInput,
  KatPageHeader,
  KatRow,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableFooter,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import React from "react";
import { Link } from "react-router-dom";
import { LABEL } from "src/constants/commonConstants";
import { FarmerProfileModal } from "../FarmerProfileModal";

const MockData = [
  {
    farmerid: "123",
    farmerName: "Example Farmer 1",
    phoneNumber: "771231238",
    vendorCode: "axs",
    status: "active",
  },
  {
    farmerid: "1213",
    farmerName: "Example Farmer 2",
    phoneNumber: "7712123238",
    vendorCode: "axasfs",
    status: "Not Active",
  },
  {
    farmerid: "11423",
    farmerName: "Example Farmer 3",
    phoneNumber: "771231238",
    vendorCode: "axs",
    status: "active",
  },
  {
    farmerid: "123",
    farmerName: "Example Farmer 4",
    phoneNumber: "678931238",
    vendorCode: "axasds",
    status: "active",
  },
  {
    farmerid: "123",
    farmerName: "Example Farmer 5",
    phoneNumber: "7456231238",
    vendorCode: "atiuoxs",
    status: "active",
  },
  {
    farmerid: "123",
    farmerName: "Example Farmer 6",
    phoneNumber: "77547238",
    vendorCode: "axs",
    status: "active",
  },
];


export default function Farmers() {
  const [showFarmerProfileModal, setShowFarmerProfileModal] =
  React.useState(false);
  const [farmerModalData, setFarmerModalData] = React.useState({
    farmerid: "",
    farmerName: "",
    phoneNumber: "",
    vendorCode: "",
    status: "",
    });

  const addFarmerHandler = () => {
    // logic for adding farmer
  };

  return (
    <>
      <FarmerProfileModal
        farmerModalData={farmerModalData}
        setFarmerModalData={setFarmerModalData}
        showFarmerProfileModal={showFarmerProfileModal}
        setShowFarmerProfileModal={setShowFarmerProfileModal}
      />
      {/* <KatPageHeader header={LABEL.FARMER_ALLOW_LISTING}></KatPageHeader> */}
      <form>
        <KatGrid
          cols-l="1"
          cols-m="1"
          cols-s="1"
          cols-xl="3"
          cols-xxl="4"
          density="low"
          variant="item"
          className="ps-3 pe-5"
        >
          <KatGridItem>
            <KatInput
              label={LABEL.NAME}
              type="text"
              placeholder={LABEL.ENTER_NAME}
              required
            ></KatInput>
          </KatGridItem>
          <KatGridItem>
            <KatInput
              type="text"
              min={0}
              inputmode="numeric"
              label={LABEL.VENDOR_ID}
              placeholder={LABEL.ENTER_VENDOR_ID}
              required
            ></KatInput>
          </KatGridItem>
          <KatGridItem>
            <KatInput
              type="number"
              min={0}
              inputmode="numeric"
              label={LABEL.PHONE_NUMBER}
              placeholder={LABEL.ENTER_PHONE_NUMBER}
              required
            ></KatInput>
          </KatGridItem>
          <KatGridItem className="flex text-center align-items-center gap-2">
            <KatButton
              type="submit"
              onClick={() => addFarmerHandler()}
              label={LABEL.ADD_FARMER}
              variant="primary"
              className="mt-3 w-15 flex justify-items-center"
            ></KatButton>
          </KatGridItem>
        </KatGrid>
      </form>
      <KatInput
        className="mt-3 m-search"
        label={LABEL.SEARCH_FARMER}
        type="text"
        placeholder={LABEL.FARMER_NAME}
      ></KatInput>
      <KatRow className="align-items-end mt-4 overflow-visible">
        <KatColumn sm={12}>
          <KatTable>
            <KatTableHead>
              <KatTableRow>
                <KatTableCell>{LABEL.FARMER_NAME}</KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.PHONE_NUMBER}
                </KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.VENDOR_ID}
                </KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.EDIT}
                </KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.STATUS}
                </KatTableCell>
              </KatTableRow>
            </KatTableHead>
            <KatTableBody>
              {MockData.map((item) => {
                return (
                  <KatTableRow>
                    <KatTableCell>
                      <Link
                        to={"#"}
                        onClick={() => {
                          setShowFarmerProfileModal(true);
                          setFarmerModalData(item);
                        }}
                      >
                        {item.farmerName}
                      </Link>
                    </KatTableCell>
                    <KatTableCell className="text-center">
                      {item.phoneNumber}
                    </KatTableCell>
                    <KatTableCell className="text-center">
                      {item.vendorCode}
                    </KatTableCell>
                    <KatTableCell className="text-center">
                      <Link to={"#"}>Edit</Link>
                    </KatTableCell>
                    <KatTableCell className="text-center">
                      {item.status}
                    </KatTableCell>
                  </KatTableRow>
                );
              })}
            </KatTableBody>
          </KatTable>
          <KatTableFooter className="p-2 h-100">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3">{MockData.length} Results</div>
            </section>
          </KatTableFooter>
        </KatColumn>
      </KatRow>
    </>
  );
}
