import { nothing, TemplateResult } from 'lit-html';
import { html, internalProperty, property } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import { ifNotNull } from '../../utils/directives';
import { checkSlots, cleanDefaultSlot } from '../../shared/slot-utils';
import {
  KatLitMobileElement,
  register,
  event,
  EventEmitter,
  KeyboardConstants,
} from '../../shared/base';
import { formInputMap } from '../../utils/form-input-map';
import { nextUniqueId } from '../../shared/base/unique-id';
import {
  getFirstMatchingParent,
  getContentOfFirstTextNode,
} from '../../shared/utils';
import getModalString from '../modal/strings';
import baseStyles from '../../shared/base/base.lit.scss';
import formItemStyles from '../../shared/base/form-item.base.lit.scss';
import { debounce } from '../../utils/debounce';
import styles from './dropdown.lit.scss';
import { fitHandler } from './fit-handler';
import { KatOption } from './option';
import { KatOptgroup } from './optgroup';
import getString from './strings';

interface KatOptionInput {
  name: string;
  value: string;
  values?: string[];
  icon?: string;
  disabled?: boolean;
}

interface KatOptgroupInput {
  label: string;
  options: KatOptionInput[];
  icon?: string;
  disabled?: boolean;
}

/**
 * @component {kat-dropdown} KatalDropdown Dropdowns allow users to make a single selection from a list of options in a compact space.
 * @subcomponent ./option.ts
 * @subcomponent ./optgroup.ts
 * @guideline Do Stringify data object when passing it to attribute data
 * @guideline Do If possible, pre-select a good default item from the list to help the customer make an informed decision, similar to having a good default input value in an input field.
 * @guideline Do If possible, only include valid options in a dropdown. A user should not be able to choose an option that is invalid.
 * @guideline Do Use flags with caution. Some may cause or add to existing localization issues.
 * @guideline Dont Don't use the descriptions under the labels for each item unless absolutely necessary.
 * @guideline Dont Don't use a flag unless you are certain it will not cause localization issues.
 * @slot default Can be used instead of the `options` property for more control over the options' appearance. Should be filled with `kat-option` or `kat-optgroup` elements.
 * @example Basic {"label": "Basic Dropdown", "value":"testValue2", "options": [{"name":"Value1","value":"testValue","icon":"optional-icon-name"},{"name":"Value2","value":"testValue2","icon":"optional-icon-name-2"},{"name":"disabledValue","value":"disabledValue","icon":"optional-icon-name", "disabled": true}]}
 * @example Large {"value":"", "options": [{"name":"car","value":"carValue"},{"name":"truck","value":"truckValue"},{"name":"house","value":"houseValue"},{"name":"plane","value":"planeValue"},{"name":"cat","value":"catValue"},{"name":"dog","value":"dogValue"},{"name":"fish","value":"fishValue"},{"name":"bird","value":"birdValue"},{"name":"lizard","value":"lizardValue"},{"name":"turtle","value":"turtleValue"},{"name":"bug","value":"bugValue"}]}
 * @example CountriesAsSlots {"rich-selection-label":"true", "constraint-emphasis":"Note:", "constraint-label":"When using slots to provide options, the styling is completely up to you. The Dropdown component will not define or restrain styles for you.", "value": "", "content": "
 *   <style>
 *       .flexed {
 *         display: flex;
 *         align-items: center;
 *       }
 *
 *       .padded {
 *         margin-left: 8px;
 *       }
 *     </style>
 *   <kat-option value=\"ca\">
 *     <div class=\"flexed\">
 *        <kat-icon name=\"flag-ca\" size=\"small\"></kat-icon>
 *         <span class=\"padded\">Canada</span>
 *       </div>
 *     </kat-option>
 *     <kat-option value=\"de\">
 *       <div class=\"flexed\">
 *         <kat-icon name=\"flag-de\" size=\"small\"></kat-icon>
 *         <span class=\"padded\">Germany</span>
 *       </div>
 *     </kat-option>
 *     <kat-option value=\"it\">
 *       <div class=\"flexed\">
 *         <kat-icon name=\"flag-it\" size=\"small\"></kat-icon>
 *         <span class=\"padded\">Italy</span>
 *       </div>
 *     </kat-option>
 *     <kat-option value=\"us\">
 *       <div class=\"flexed\">
 *         <kat-icon name=\"flag-us\" size=\"small\"></kat-icon>
 *         <span class=\"padded\">United States</span>
 *       </div>
 *     </kat-option>
 * "}
 * @example CountriesAsOptions {"rich-selection-label":"true", "placeholder":"Countries", "options": [{"name":"USA","value":"us","icon":"flag-us"},{"name":"Canada","value":"ca","icon":"flag-ca"},{"name":"Mexico","value":"mx","icon":"flag-mx"},{"name":"New Zealand","value":"nz"},{"name":"UAE","value":"uae" }]}
 * @example WithIcons {"options": [{"name":"info","value":"info","icon":"info"},{"name":"input","value":"input","icon":"input"},{"name":"inbox","value":"inbox","icon":"inbox"}]}
 * @example Error {"value": "", "placeholder": "Pick a topping", "state": "error", "options": [{"name":"Pepperoni","value":"pepperoni"},{"name":"Onions","value":"onions"},{"name":"Pineapple","value":"pineapple"}]}
 * @example WithLabels {"value": "", "placeholder": "Pick a topping", "state-label": "Error with selection",
 * "state-emphasis": "Error", "label": "Dropdown with labels", "constraint-emphasis": "Note", "constraint-label": "This is a constraint label",
 * "options": [{"name":"Pepperoni","value":"pepperoni"},{"name":"Onions","value":"onions"},{"name":"Pineapple","value":"pineapple"}]}
 * @example WithTooltip {"label": "Dropdown with tooltip", "tooltip-text": "Hello world",
 * "options": [{"name":"Pepperoni","value":"pepperoni"},{"name":"Onions","value":"onions"},{"name":"Pineapple","value":"pineapple"}]}
 * @example WithShowClear {"show-clear": true, "value":"testValue2", "options": [{"name":"Value1","value":"testValue","icon":"optional-icon-name"},{"name":"Value2","value":"testValue2","icon":"optional-icon-name-2"},{"name":"disabledValue","value":"disabledValue","icon":"optional-icon-name", "disabled": true}]}
 * @example MultipleBasic {"multiple": true, "searchable": true, "label": "Basic Multiple Dropdown", "options": [{"name":"Value1","value":"testValue1","icon":"optional-icon-name"},{"name":"Value2","value":"testValue2","icon":"optional-icon-name-2"},{"name":"disabledValue","value":"disabledValue","icon":"optional-icon-name", "disabled": true},{"name":"Value3","value":"testValue3"}]}
 * @example MultipleShowApply {"multiple": true, "multiple-show-apply": true, "options": [{"name":"Value1","value":"testValue1","icon":"optional-icon-name"},{"name":"Value2","value":"testValue2","icon":"optional-icon-name-2"},{"name":"disabledValue","value":"disabledValue","icon":"optional-icon-name", "disabled": true},{"name":"Value3","value":"testValue3"}]}
 * @example MultipleHideSelectAll {"multiple": true, "multiple-hide-select": true, "options": [{"name":"Value1","value":"testValue1","icon":"optional-icon-name"},{"name":"Value2","value":"testValue2","icon":"optional-icon-name-2"},{"name":"disabledValue","value":"disabledValue","icon":"optional-icon-name", "disabled": true},{"name":"Value3","value":"testValue3"}]}
 * @example DropdownGroupBasic {"label": "Basic Dropdown Group", "placeholder": "Countries", "options": [{"label": "Test Group", "options": [{"name":"India", "value":"in"}, {"name": "Japan", "value":"jp", "icon":"flag-jp"}]},{"name":"USA","value":"us","icon":"flag-us"},{"name":"Canada","value":"ca","icon":"flag-ca"},{"name":"Mexico","value":"mx","icon":"flag-mx"},{"name":"New Zealand","value":"nz"},{"name":"UAE","value":"uae"}]}
 * @example DropdownGroupMultiple {"label": "MultiSelect Dropdown Group", "placeholder": "Countries", "multiple": true, "searchable": true, "options": [{"label": "Test Group One", "icon": "flag-ae", "options": [{"name":"India", "value":"in"}, {"name": "Japan", "value":"jp", "icon":"flag-jp"}, {"name": "Another value", "value": "val"}]},{"label": "Test Group Two - Disabled", "disabled": true, "options": [{"name":"Austria", "value":"at", "icon": "flag-at"}, {"name": "Australia", "value":"au", "icon":"flag-au"}]}, {"name":"USA","value":"us","icon":"flag-us"},{"name":"Canada","value":"ca","icon":"flag-ca"},{ "label": "Test Group Three with a really long name that should overflow", "options": [{"name":"Belgium", "value":"be", "icon": "flag-be"}, {"name": "Bulgaria", "value":"bg", "icon":"flag-bg"}]},{"name":"Mexico","value":"mx","icon":"flag-mx"},{"name":"New Zealand","value":"nz"},{"name":"UAE","value":"uae"}] }
 * @example DropdownGroupSlots {"label": "Dropdown Group with Slots", "placeholder": "Countries", "multiple": true, "content": "<kat-optgroup><span slot=\"label\"><div class=\"flexed\"><kat-icon name=\"work\" size=\"small\"></kat-icon><span class=\"padded\">Not North America</span></div></span><kat-option value=\"ca\"><div class=\"flexed\"><kat-icon name=\"flag-ca\" size=\"small\"></kat-icon><span class=\"padded\">Canada</span></div></kat-option><kat-option value=\"de\"><div class=\"flexed\"><kat-icon name=\"flag-de\" size=\"small\"></kat-icon><span class=\"padded\">Germany</span></div></kat-option></kat-optgroup><kat-option value=\"it\"><div class=\"flexed\"><kat-icon name=\"flag-it\" size=\"small\"></kat-icon><span class=\"padded\">Italy</span></div></kat-option><kat-option value=\"us\"><div class=\"flexed\"><kat-icon name=\"flag-us\" size=\"small\"></kat-icon> <span class=\"padded\">United States</span></div></kat-option>"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-dropdown')
export class KatDropdown extends KatLitMobileElement {
  // #region PUBLIC MEMBERS

  /** Array of option objects providing the name, value, and optional icon. Each array element represents a single dropdown option. */
  @property()
  options: KatOptionInput[] | KatOptgroupInput[] = [];

  /** The text that displays to the user as a placeholder. */
  @property()
  placeholder?: string;

  /**
   * The max height of the selection area.
   * This should be a css value with units, e.g. "8px".
   */
  @property({ attribute: 'max-height' })
  maxHeight?: string;

  /** The optional field name, hidden from view. */
  @property()
  name?: string;

  /** Whether the component is currently showing its dropdown options. */
  @property()
  expanded?: boolean;

  /** The value of the selected option. */
  @property()
  value?: string;

  /** The value of the selected options if multiple. */
  @property({ type: Array, reflect: true })
  values?: string[];

  /**
   * If set to `true`, the selection value will be shown as it appears in the selection option.
   * Otherwise only the selection value will be shown as simple text.
   * Defaults to `false`
   */
  @property({ attribute: 'rich-selection-label' })
  richSelectionLabel? = false;

  /**
   * The size of the selected option.
   * @enum {value} large Large select box - Default
   * @enum {value} small Small select box
   */
  @property()
  size: 'large' | 'small' = 'large';

  /** Setting the select box to disabled stops its interactivity. */
  @property()
  disabled?: boolean;

  /** Whether single selection shows the clear button. Defaults to `false`. */
  @property({ attribute: 'show-clear' })
  showClear?: boolean;

  /** Whether multiple selection is available. */
  @property()
  multiple?: boolean;

  /** Whether multiple selection shows apply/cancel buttons. Requires `multiple`. Defaults to `false`. */
  @property({ attribute: 'multiple-show-apply' })
  multipleShowApply?: boolean;

  /** Whether mutliple selection apply/cancel buttons 'stick' to the bottom of the visible dropdown.
   * Requires `multiple` and `multiple-show-apply`.
   * Does not apply if maxHeight property is less than dropdown footer height.
   * Defaults to false.
   */
  @property({ attribute: 'multiple-sticky-apply' })
  multipleStickyApply?: boolean;

  /** Whether multiple selection shows select-all button. Requires `multiple`. Defaults to `false`. */
  @property({ attribute: 'multiple-hide-select' })
  multipleHideSelect?: boolean;

  /** The label of the dropdown visible only to screenreaders. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /** The optional field label. */
  @property()
  label?: string;

  /** Override optional mobile header.  Defaults to label, placeholder, or hidden element. */
  @property({ attribute: 'mobile-label' })
  mobileLabel?: string;

  /**
   * Optional pretext for the constraint label.  Used to
   * provide additional context to the constraint for the input.
   */
  @property({ attribute: 'constraint-emphasis' })
  constraintEmphasis?: string;

  /** Provides users with more information about what they enter into the input. */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /**
   * Setting the state of the input changes its look to give the user more information about what they have entered. This value must be set for State Labels to show up.
   * @enum {value} error Lets the user know there is a problem with the value they have entered in the input.
   */
  @property()
  state?: string;

  /** Optional pretext for the state label. Provides additional context to the state for the input. */
  @property({ attribute: 'state-emphasis' })
  stateEmphasis?: string;

  /** Provides users with more information about why the input is in the state it is in. */
  @property({ attribute: 'state-label' })
  stateLabel?: string;

  /**
   * The text to be shown inside the tooltip placed next to the label text. The tooltip will only appear if this is
   * set.
   */
  @property({ attribute: 'tooltip-text' })
  tooltipText?: string;

  /** The position of the tooltip. Defaults to "top". */
  @property({ attribute: 'tooltip-position' })
  tooltipPosition?: string;

  /** The icon that triggers the tooltip next to the label. Defaults to "help_outline". */
  @property({ attribute: 'tooltip-trigger-icon' })
  tooltipTriggerIcon?: string;

  /** Setting the select box to searchable adds a filter box to refine the options displayed. */
  @property()
  searchable?: boolean;

  // Needs to be in the property list so that changing it will re-render the component.
  @internalProperty()
  _searchTerm: string;

  /** Locale for the "no options" text in search bar */
  @property()
  locale?: string;

  /** Override function for selection summary. */
  @property({ type: Function, attribute: false })
  selectionSummaryOverride?: (option: any) => string;

  /**
   * @enum {value} auto Dropdown menu will automatically decide which direction to expand (up/down) based on available space below the Dropdown. Default.
   * @enum {value} down Dropdown menu will always expand down, even if there isn't space in the container to expand down. Use if your container will resize after the Dropdown is opened.
   */
  @property({ attribute: 'expand-direction' })
  expandDirection?: 'auto' | 'down' = 'auto';

  /** Fires after the dropdown value has changed. */
  @event('change', true)
  private _change: EventEmitter<{ value: string; values?: string[] }>;

  /** Fires whenever the dropdown is expanded. */
  @event('expand')
  private _expand: EventEmitter;

  /** Fires whenever the dropdown is collapsed. */
  @event('collapse')
  private _collapse: EventEmitter;

  /**
   * Programmatically focus the header.
   * @katalmethod
   */
  public focus() {
    const header = this._shadow('.select-header') as HTMLElement;
    header?.focus();
  }

  /**
   * Programmatically select a given option. To select a group, repeat for each option in the group.
   * @katalmethod
   */
  public async selectOption(element: KatOption) {
    if (element.tagName !== this._katOptionTagname.toUpperCase()) {
      return;
    }

    this.setOptionSelected(element, true);
    await this.requestUpdateAndEmitValues();
  }

  static get styles() {
    return [baseStyles, formItemStyles, styles];
  }

  // #endregion

  // #region PRIVATE MEMBERS

  private _jumpToSearch = '';
  private _jumpToSearchTimeout = 300;
  private _timeoutId = 0;
  private _uniqueId = nextUniqueId();
  private _selectAllOptionId = 'select-all';
  private _pendingApplyButtonId = 'pending-apply';
  private _pendingCancelButtonId = 'pending-cancel';
  private _searchInputId = 'search-input';
  private _selectedOptionSlotName = 'selected-option';
  private _selectHeaderSelector = 'div.select-header';
  private _rootContainerClassname = 'kat-select-container';
  private _katOptionTagname = 'kat-option';
  private _katOptgroupTagname = 'kat-optgroup';

  // Trigger for "no options" message
  private _allOptionsAreHidden = false;

  // When multipleShowApply is set, temporarily store changes
  private _pendingOptionSelections: KatOption[] = [];

  // Store cache of last selected summary so we don't update if multipleShowApply
  private _lastSelectedSummary: TemplateResult[] = [];

  private _fitHandler;

  // #endregion

  // #region Lifecycle

  constructor() {
    super();

    this._listeners = [
      {
        target: window,
        listeners: [['resize', () => this.onResizeDebounced()]],
      },
      [
        'focusout',
        e => {
          const container = this._shadow(`.${this._rootContainerClassname}`);
          const newFocus = e.relatedTarget as Node;
          if (
            this.expanded &&
            !this.disabled &&
            !container.contains(newFocus) &&
            !this.contains(newFocus)
          ) {
            this.collapse();
          }
        },
      ],
    ];

    cleanDefaultSlot(this);
    this.observeChildren(this, () => this.requestUpdate());
  }

  // Only used for searchable drop-downs. This responds to the input field.
  @debounce(150)
  onInputDebounced(searchTerm: string) {
    this._searchTerm = searchTerm;

    if (this._hasSlottedOptions()) {
      this.toggleElementVisibility();
    }
  }

  updateOptionsAndSelectedSingle() {
    this.setSelectedBasedOnValue();
    if (this.richSelectionLabel) {
      this._setSelectionAsRichLabel();
    }
  }

  updateOptionAndSelectedMultiple() {
    this.setSelectedBasedOnValues();
    this._updateSelectedSummaryOverflow();
  }

  setSelectedBasedOnValue() {
    this._getOptionsRendered().forEach(option => {
      option.selected = !!option.value && option.value === this.value;
    });
  }

  setSelectedBasedOnValues() {
    this._getSelectablesRendered().forEach(option => {
      if (this.isOptgroupElement(option)) {
        const childOptions = Array.from(
          option.querySelectorAll<KatOption>(this._katOptionTagname)
        );

        option.selected = childOptions.every(
          childOption =>
            !!childOption.value && this.values?.includes(childOption.value)
        );

        option.indeterminate =
          !option.selected &&
          childOptions.some(
            childOption =>
              !!childOption.value && this.values?.includes(childOption.value)
          );
      } else {
        option.selected = !!option.value && this.values?.includes(option.value);
      }
    });
  }

  calculateGroupClasses() {
    // Attach .sibling class to optgroups if they have an adjacent optgroup (combinator CSS selectors cannot be used for slots)
    const defaultSlots = Array.from(
      this.shadowRoot.querySelectorAll<HTMLSlotElement>('slot:not([name])')
    );
    const slottedElements = defaultSlots
      .flatMap(slot => slot.assignedElements({ flatten: true }))
      .filter(
        slottedElement =>
          !slottedElement.classList.contains('search-term-hidden') &&
          !slottedElement.attributes.getNamedItem('hidden')
      );

    slottedElements.forEach((currSlot, index, allSlots) => {
      if (
        index >= 1 &&
        this.isOptgroupElement(currSlot) &&
        this.isOptgroupElement(allSlots[index - 1])
      ) {
        currSlot.classList.add('sibling');
      } else {
        currSlot.classList.remove('sibling');
      }

      // Attach .last class to optgroup if it is the last child in the dropdown.
      currSlot.classList.remove('last');
      if (index === allSlots.length - 1 && this.isOptgroupElement(currSlot)) {
        currSlot.classList.add('last');
      }
    });
  }

  @debounce(150)
  onResizeDebounced() {
    this._updateSelectedSummaryOverflow();
  }

  shouldUpdate(changedProperties) {
    if (changedProperties.has('value')) {
      this.setSelectedBasedOnValue();
    }

    if (changedProperties.has('values')) {
      this.setSelectedBasedOnValues();
    }

    // Values are not updated immediately when multipleShowApply === true, but slotted
    // optgroups still need to update their selected or indeterminate state.
    if (this.multipleShowApply) {
      this._getSelectablesRendered().forEach(selectable => {
        if (this.isOptgroupElement(selectable)) {
          const renderedChildOptions = Array.from(
            selectable.querySelectorAll<KatOption>(this._katOptionTagname)
          );

          if (renderedChildOptions.length) {
            selectable.selected = renderedChildOptions.every(
              childOption => childOption.selected
            );

            selectable.indeterminate =
              renderedChildOptions.some(childOption => childOption.selected) &&
              !selectable.selected;
          }
        }
      });
    }

    return true;
  }

  @formInputMap([
    {
      tag: 'select',
      name: (component: KatDropdown) => component.name,
      isNeeded: (component: KatDropdown) => component.isFormInputNeeded(),
      setup: (component: KatDropdown, select: HTMLSelectElement) =>
        component.setupFormInput(select),
    },
  ])
  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('_searchTerm')) {
      this.calculateGroupClasses();
    }

    if (changedProperties.has('expanded')) {
      this.setOrUnsetOptionsContainerBottomOffset();
    }

    if (
      changedProperties.has('value') ||
      changedProperties.has('locale') ||
      (!this.multiple &&
        changedProperties.has('options') &&
        !this._hasSlottedOptions())
    ) {
      this.updateOptionsAndSelectedSingle();
    }

    if (
      changedProperties.has('values') ||
      changedProperties.has('locale') ||
      (this.multiple &&
        changedProperties.has('options') &&
        !this._hasSlottedOptions())
    ) {
      this.updateOptionAndSelectedMultiple();
    }

    if (this.multiple && this.richSelectionLabel) {
      console.warn(
        'Using rich-selection-label with multiple is not supported.'
      );
    }
  }

  isFormInputNeeded() {
    return !!(
      !this.disabled &&
      this.name &&
      (this.value || this.values?.length > 0)
    );
  }

  setupFormInput(select: HTMLSelectElement) {
    const items = this.multiple ? this.values : [this.value];

    select.multiple = this.multiple;
    select.innerHTML = items
      .map(val => `<option value='${val}' selected>${val}</option>`)
      .join('');
  }

  // #endregion

  // #region Visual/Option-Related

  _setSelectionAsRichLabel() {
    const selectedOption = this._getOptionsSelected()[0];
    const previousSlotContent = this.querySelector(
      `[slot="${this._selectedOptionSlotName}"]`
    );

    // Always remove old content
    if (previousSlotContent) {
      this.removeChild(previousSlotContent);
    }

    if (!selectedOption) {
      return;
    }

    let richSelectionElement: HTMLElement;

    // Clone the first child OR create a span for the innerText
    if (selectedOption.firstElementChild) {
      richSelectionElement = selectedOption.firstElementChild.cloneNode(
        true
      ) as HTMLElement;
    } else {
      const span = document.createElement('span');
      span.innerText = selectedOption.innerText;
      richSelectionElement = span;
    }

    richSelectionElement.setAttribute('slot', this._selectedOptionSlotName);
    this.appendChild(richSelectionElement);
  }

  // Only for slots and only if the list is searchable.
  toggleElementVisibility() {
    let hasVisibleOptions = false;

    const options = Array.from(
      this.getElementsByTagName(this._katOptionTagname)
    ) as KatOption[];
    const optgroups = Array.from(
      this.getElementsByTagName(this._katOptgroupTagname)
    ) as KatOptgroup[];

    [...options, ...optgroups].forEach(element => {
      const searchKey = element.searchkey;
      let textContent: string;
      if (this.isOptgroupElement(element)) {
        // if optgroup, use textContent of label slot.
        textContent = element.firstElementChild.textContent;
      } else {
        // if option, use textContent
        textContent = element.textContent;
      }

      // if an optgroup has a child matching the search term, display the optgroup as well
      const shouldDisplayOptGroup =
        this.isOptgroupElement(element) &&
        Array.from(element.getElementsByTagName(this._katOptionTagname)).some(
          childEl => this.matchesSearchTerm(childEl.textContent)
        );

      // if a search term matches an optgroup, display all children of that optgroup
      const shouldDisplayChild =
        element.parentElement.tagName ===
          this._katOptgroupTagname.toUpperCase() &&
        this.matchesSearchTerm(
          element.parentElement.firstElementChild.textContent
        );

      if (
        this._searchTerm &&
        ((!searchKey &&
          !this.matchesSearchTerm(textContent) &&
          !shouldDisplayOptGroup &&
          !shouldDisplayChild) ||
          (searchKey &&
            !this.matchesSearchTerm(searchKey) &&
            !shouldDisplayOptGroup &&
            !shouldDisplayChild))
      ) {
        element.classList.add('search-term-hidden');
      } else {
        element.classList.remove('search-term-hidden');
        hasVisibleOptions = true;
      }
    });

    this._allOptionsAreHidden = !hasVisibleOptions;
  }

  async toggleExpanded() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expanded = true;
      await this.updateComplete;
      this.moveFocus();
      this._expand.emit();
    }
  }

  async collapseAndFocus() {
    this.focus();
    await this.collapse();
  }

  async collapse() {
    // Next time it's opened, all items should be displayed
    if (this.searchable) {
      this._searchTerm = '';
      this.toggleElementVisibility();
    }

    // Reset any pending selections
    this._pendingOptionSelections.forEach(
      option => (option.selected = !option.selected)
    );
    this._pendingOptionSelections = [];

    this.expanded = false;
    await this.updateComplete;
    this._collapse.emit();
  }

  private matchesSearchTerm(optionText: string) {
    return optionText.toLowerCase().includes(this._searchTerm.toLowerCase());
  }

  async searchOptions(autoselect) {
    const search = this._jumpToSearch.toLowerCase();
    const options = this._getSelectablesRendered();
    const matching = options.find(opt => {
      const text = getContentOfFirstTextNode(opt);
      return text?.toLowerCase().startsWith(search) && !opt.disabled;
    });
    if (matching) {
      if (autoselect && this.expanded !== true) {
        this.setOptionSelected(matching, true);
        await this.requestUpdateAndEmitValues();
      } else {
        if (!this.expanded) {
          this.expanded = true;
          await this.updateComplete;
        }
        matching.focus();
      }
    }
  }

  updateValuesFromOption(option: KatOption | KatOptgroup) {
    // Optgroups appear alongside options, but are only containers with no intrinsic value themselves.
    if (this.isOptgroupElement(option)) {
      return;
    }

    if (option.selected) {
      this.values = [...(this.values || []), option.value];
    } else {
      // No optional chaining since option being unselected means it's value must've existed in this.values
      this.values = this.values.filter(value => value !== option.value);
    }
  }

  setOptionSelected(option: KatOption | KatOptgroup, selected: boolean) {
    if (option.disabled) {
      return;
    }

    // Bulk operations blindly call this method, so we short-circuit if nothing to change.
    // option.selected could be undefined, so need to convert to boolean
    if (!!option.selected === selected) {
      return;
    }

    option.selected = selected;

    if (this.multiple) {
      if (this.multipleShowApply) {
        const inPendingSelections = this._pendingOptionSelections.some(
          o => option === o
        );
        if (inPendingSelections) {
          this._pendingOptionSelections = this._pendingOptionSelections.filter(
            o => option !== o
          );
        } else {
          this._pendingOptionSelections = [
            ...this._pendingOptionSelections,
            option,
          ];
        }
      } else {
        this.updateValuesFromOption(option);
      }
    } else if (!this.isOptgroupElement(option)) {
      this.value = option.selected ? option.value : undefined;
    }
  }

  async requestUpdateAndEmitValues() {
    await this.requestUpdate();
    this.emitValues();

    if (!this.multiple) {
      this.collapseAndFocus();
    }
  }

  // Emits current values.  Will skip if multipleShowApply is true, unless forceIfShowApply is passed in as true.
  emitValues(bypassMultipleShowApply = false) {
    if (this.multiple && this.multipleShowApply && !bypassMultipleShowApply) {
      return;
    }

    this._change.emit({ value: this.value, values: this.values });
  }

  async clearAllOptions() {
    await this.setAllOptions(false);
  }

  async setAllOptions(selected: boolean) {
    this._getOptionsRendered().forEach(option =>
      this.setOptionSelected(option, selected)
    );
    await this.updateComplete;
    this.emitValues();
  }

  // Useful for focusing a kat-option by its value.
  focusOptionByValue(value: string): boolean {
    const foundOption = this._getOptionsRendered().find(
      option => option.value === value
    );

    if (foundOption && !foundOption.disabled) {
      foundOption.focus();
      return true;
    }

    return false;
  }

  /**
   * Calling with no parameters assumes tries to focus in the following order:
   *  - By value for last entry in `values` array.
   *  - By value for the `value` property.
   *  - First item in linked list.
   */
  moveFocus(
    key: string = KeyboardConstants.ARROW_DOWN,
    target?: KatOption | KatOptgroup
  ) {
    const linkedList = [
      ...this._getSelectablesRendered().filter(
        option => !option.classList.contains('search-term-hidden')
      ),
    ] as HTMLElement[];

    if (this.multiple && !this.multipleHideSelect && !this._searchTerm) {
      linkedList.unshift(
        this.shadowRoot.getElementById(this._selectAllOptionId)
      );
    }

    if (this.searchable) {
      linkedList.unshift(this.shadowRoot.getElementById(this._searchInputId));
    }

    if (this.multiple && this.multipleShowApply) {
      linkedList.push(
        this.shadowRoot.getElementById(this._pendingCancelButtonId)
      );
      linkedList.push(
        this.shadowRoot.getElementById(this._pendingApplyButtonId)
      );
    }

    // No target = target last selected item or initial item
    if (!target) {
      // Values could be set to an option that does not match any options
      if (
        this.values?.length > 0 &&
        this.focusOptionByValue(this.values[this.values.length - 1])
      ) {
        return;
      }

      // Value could be set to an option that does not match any options
      if (this.value && this.focusOptionByValue(this.value)) {
        return;
      }

      // Last chance to focus - pick first option that's not disabled
      if (linkedList.length > 0) {
        const firstNonDisabledOption = linkedList.find(
          selectable => !selectable.hasAttribute('disabled')
        );

        if (firstNonDisabledOption) {
          firstNonDisabledOption.focus();
        }

        // Even if all options are disabled, we still want to open the dropdown
        return;
      }
    }

    if (key === KeyboardConstants.ARROW_UP) {
      linkedList.reverse();
    }

    let skipElement = true;

    // Loop through the available options until we find the next, non-disabled one from our target
    const foundElementToFocus = linkedList.some(element => {
      // Skip everything until we find the target
      if (skipElement) {
        skipElement = element !== target;
        return false;
      }

      // We found the target, take the next non-disabled item
      if (
        element &&
        !element.hasAttribute('disabled') &&
        !element.hasAttribute('hidden')
      ) {
        if (this.isOptgroupElement(element)) {
          // focus on the header of an optgroup instead of the entire group
          element.shadowRoot
            .querySelector<HTMLElement>('.header-wrapper')
            .focus();
        } else {
          element.focus();
        }
        return true;
      }
    });

    if (!foundElementToFocus) {
      this.collapseAndFocus();
    }
  }

  fitDropDownWindow() {
    const optionContainer = this._shadow('.select-options') as HTMLElement;
    const optionInnerContainer = optionContainer.querySelector<HTMLElement>(
      '.option-inner-container'
    );
    const searchContainer =
      optionContainer.querySelector<HTMLElement>('.search-wrapper');
    const searchContainerHeight = searchContainer?.offsetHeight || 0;

    optionContainer.style.display = 'table';
    this._fitHandler = fitHandler(
      this,
      optionInnerContainer,
      !this.searchable && this.expandDirection === 'auto',
      {
        additionalPaddingBelow: searchContainerHeight,
      }
    );
    optionContainer.style.removeProperty('display');
  }

  setOrUnsetOptionsContainerBottomOffset() {
    const selectOptionsElement = this._shadow('.select-options');

    this.fitDropDownWindow();

    if (this.hasAttribute('drop-up')) {
      const header = this._shadow(this._selectHeaderSelector) as HTMLElement;

      selectOptionsElement.setAttribute(
        'style',
        `bottom: ${header.offsetHeight}px`
      );
    } else {
      selectOptionsElement.removeAttribute('style');
    }
  }

  async jumpToSearch(key, autoselect: boolean) {
    this._jumpToSearch += key;

    clearTimeout(this._timeoutId);
    await this.searchOptions(autoselect);

    // Using fully qualified window.setTimeout call resolves the origin ambiguity for eslinter.
    // We plan on upgrading this component ground up so that it does not need setTimeout-s in the first place.
    this._timeoutId = window.setTimeout(
      () => (this._jumpToSearch = ''),
      this._jumpToSearchTimeout
    );
  }

  isOptionHidden(option: KatOptionInput | KatOptgroupInput) {
    if (!this.searchable || !this._searchTerm) {
      return false;
    }

    if (this.isOptgroupInput(option)) {
      return (
        !option.options.some(childOption =>
          this.matchesSearchTerm(childOption.name)
        ) && !this.matchesSearchTerm(option.label)
      );
    }
    return !this.matchesSearchTerm(option.name);
  }

  // #endregion

  // #region Utilities

  _hasSlottedOptions() {
    return !!checkSlots(this).default;
  }

  _getOptionsSelected(): KatOption[] {
    return this._getOptionsRendered().filter(option => option.selected);
  }

  _getOptionsRendered(): KatOption[] {
    const elements = this._hasSlottedOptions()
      ? this.getElementsByTagName(this._katOptionTagname)
      : this.shadowRoot.querySelectorAll(
          `.option-inner-container ${this._katOptionTagname}:not([id=${this._selectAllOptionId}])`
        );

    return Array.from(elements) as KatOption[];
  }

  // Similar to _getOptionsRendered(), but includes KatOptgroup elements as well when dropdown is multi-selectable
  _getSelectablesRendered(): (KatOption | KatOptgroup)[] {
    if (!this.multiple) {
      return this._getOptionsRendered();
    }

    if (this._hasSlottedOptions()) {
      const elements = this.querySelectorAll(
        `${this._katOptionTagname}, ${this._katOptgroupTagname}`
      );
      return Array.from(elements) as (KatOption | KatOptgroup)[];
    }

    const elements = this.shadowRoot.querySelectorAll(
      `.option-inner-container ${this._katOptionTagname}:not([id=${this._selectAllOptionId}]), ${this._katOptgroupTagname}`
    );
    return Array.from(elements) as (KatOption | KatOptgroup)[];
  }

  // KatOptgroupInput TypeGuard: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
  private isOptgroupInput(
    option: KatOptionInput | KatOptgroupInput
  ): option is KatOptgroupInput {
    return 'options' in option;
  }

  private isOptgroupElement(
    optionElement: KatOption | KatOptgroup | HTMLElement | Element
  ): optionElement is KatOptgroup {
    return optionElement.tagName === 'KAT-OPTGROUP';
  }

  // #endregion

  // #region Pointer/Mouse Events

  handleHeaderClick(event) {
    const container = this._shadow(`.${this._rootContainerClassname}`);
    const newFocus = event.relatedTarget;
    if (!this.disabled && !container.contains(newFocus)) {
      this.toggleExpanded();
    }
  }

  // "X" button in the header when multiple
  async handleHeaderClearClick(event) {
    // Stops the event from bubbling up to handleHeaderClick, which would open the dropdown
    event.stopImmediatePropagation();
    await this.clearAllOptions();
  }

  handleSearchBarInput(event) {
    this.onInputDebounced(event.target.value);
  }

  handleSearchClearClick(event) {
    event.preventDefault();
    this._searchTerm = '';

    const input = this._shadow(
      "input[part='search-input']"
    ) as HTMLInputElement;
    input.value = '';

    if (this._hasSlottedOptions()) {
      this.toggleElementVisibility();
    }
  }

  handleSlotChange() {
    if (this.values) {
      this.updateOptionAndSelectedMultiple();
    } else if (this.value) {
      this.updateOptionsAndSelectedSingle();

      // Static consumer - https://issues.amazon.com/issues/KDS-4483
    } else {
      this._getOptionsSelected().forEach(option =>
        this.updateValuesFromOption(option)
      );
    }
  }

  async handleSelectAllClick(event) {
    // Coerce undefined to boolean
    const currentlySelected = !!event.target.selected;
    event.target.selected = !event.target.selected;
    await this.setAllOptions(!currentlySelected);

    // Required to visually update apply button
    await this.requestUpdate();
  }

  // Handles Options and Select-All
  async handleOptionClick(event) {
    if (this.disabled) {
      return;
    }

    const element = getFirstMatchingParent(
      event.target,
      el =>
        el.hasAttribute('value') ||
        (this.isOptgroupElement(el) && this.multiple)
    );

    let collapseAndFocusHandled = false;

    // Make sure we find a matching option and it's not the outer kat-dropdown
    if (element && element !== this) {
      const option = element as KatOption | KatOptgroup;

      if (option.disabled) {
        return;
      }

      const newSelectedValue = !option.selected;

      // Single selection cannot unselected in the UI
      // But for backwards compatability, we close the dropdown
      if (this.multiple || (!this.multiple && newSelectedValue)) {
        const childOptions = Array.from(
          option.querySelectorAll<KatOption>(this._katOptionTagname)
        );

        if (childOptions.length) {
          childOptions.forEach(childOption =>
            this.setOptionSelected(childOption, newSelectedValue)
          );
        }

        this.setOptionSelected(option, newSelectedValue);
        await this.requestUpdateAndEmitValues();

        // In this scenario, collapseAndFocus is already handled.
        // If we don't capture, we'll get duplicate collapse events for single selection.
        collapseAndFocusHandled = true;
      }
    }

    // Multiple is handled in a different codepath and single is handled above
    // istanbul ignore else
    if (!this.multiple && !collapseAndFocusHandled) {
      this.collapseAndFocus();
    }
  }

  async handlePendingCancelClick() {
    await this.collapse();
  }

  handlePendingApplyClick() {
    this._pendingOptionSelections.forEach(option =>
      this.updateValuesFromOption(option)
    );
    this._pendingOptionSelections = [];
    this.emitValues(true);
    this.collapseAndFocus();
  }

  // #endregion

  // #region Keyboard Events

  async handleHeaderKeydown(event) {
    const { key } = event;
    if (
      key === KeyboardConstants.ARROW_DOWN ||
      key === KeyboardConstants.ENTER ||
      (key === KeyboardConstants.SPACE && !this._jumpToSearch)
    ) {
      event.preventDefault();
      this.expanded = true;
      await this.updateComplete;
      this.moveFocus();
    } else {
      this.jumpToSearch(key, true);
    }
  }

  async handlerHeaderClearKeydown(event) {
    const { key } = event;
    if (key === KeyboardConstants.ENTER || key === KeyboardConstants.SPACE) {
      // Stops the event from bubbling up to handleHeaderClick, which would open the dropdown
      event.stopImmediatePropagation();
      await this.clearAllOptions();
    }
  }

  handleCommonKeydownEvents(event): boolean {
    const { key, target } = event;

    if (
      key === KeyboardConstants.ARROW_UP ||
      key === KeyboardConstants.ARROW_DOWN
    ) {
      event.preventDefault();
      this.moveFocus(key, target);
      return true;
    }

    if (key === KeyboardConstants.ESCAPE) {
      this.collapseAndFocus();
      return true;
    }

    if (key === KeyboardConstants.TAB) {
      return true;
    }

    return false;
  }

  handleSearchBarKeydown(event) {
    this.handleCommonKeydownEvents(event);
  }

  handleSelectAllKeydown(event) {
    const { key } = event;
    if (key === KeyboardConstants.SPACE || key === KeyboardConstants.ENTER) {
      this.handleSelectAllClick(event);
    } else {
      this.handleCommonKeydownEvents(event);
    }
  }

  async handleOptionKeydown(event) {
    const { key, target } = event;

    if (this.disabled || !target) {
      return;
    }

    if (
      (key === KeyboardConstants.SPACE && !this._jumpToSearch) ||
      key === KeyboardConstants.ENTER
    ) {
      event.preventDefault();
      await this.handleOptionClick(event);
    } else if (!this.handleCommonKeydownEvents(event)) {
      this.jumpToSearch(key, true);
    }
  }

  // #endregion

  // #region Renderers

  renderMobileHeader() {
    const header = this.mobileLabel || this.label || this.placeholder;
    if (!header) {
      return nothing;
    }

    return super.getMobileHeader(
      this.expanded,
      this.toggleExpanded,
      getModalString('kat-modal-close', null, this.locale),
      header
    );
  }

  renderOption(
    option: KatOptionInput,
    index: string,
    hidden: boolean,
    parentDisabled = false
  ) {
    const { name, value, icon, disabled } = option;
    const selected = value && this.value === value;
    const iconElement = icon
      ? html`<kat-icon name="${icon}" size="tiny"></kat-icon>`
      : nothing;

    return html`
      <kat-option
        part="dropdown-option${index}"
        ?selected="${selected}"
        tabindex="-1"
        value="${value}"
        ?disabled=${parentDisabled || disabled}
        ?multiple=${this.multiple}
        ?hidden=${hidden}
      >
        <div class="standard-option-content">
          <div class="standard-option-name">${name}</div>
          <div class="standard-option-icon">${iconElement}</div>
        </div>
      </kat-option>
    `;
  }

  renderOptgroup(optgroup: KatOptgroupInput, index: number, hidden: boolean) {
    const { label, options, icon, disabled } = optgroup;

    // If the search term matches the optgroup itself, display all children as well.
    // Otherwise, only if there are matching children, display them and the optgroup.
    const showAllChildren =
      this.searchable && this._searchTerm && this.matchesSearchTerm(label);

    const renderedChildOptions = options.map((childOption, childIndex) =>
      this.renderOption(
        childOption,
        `${index}-${childIndex}`,
        showAllChildren ? false : this.isOptionHidden(childOption),
        disabled
      )
    );

    const selected = renderedChildOptions.every(
      option =>
        !option.getTemplateElement().hasAttribute('disabled') &&
        option.getTemplateElement().hasAttribute('selected')
    );
    const indeterminate =
      renderedChildOptions.some(option =>
        option.getTemplateElement().hasAttribute('selected')
      ) && !selected;

    return html`
      <kat-optgroup
        part="dropdown-optgroup${index}"
        label=${label}
        .icon=${icon}
        ?disabled=${disabled}
        ?selected=${selected}
        ?multiple=${this.multiple}
        ?hidden=${hidden}
        ?indeterminate=${indeterminate}
      >
        ${renderedChildOptions}
      </kat-optgroup>
    `;
  }

  renderOptions() {
    // `nothing` is truthy. If we don't have JSON defined options we need to render an empty default
    // slot for the slotted content.
    if (this._hasSlottedOptions()) {
      return nothing;
    }

    const options = this.options.map((option, index) => {
      if (this.isOptgroupInput(option)) {
        return this.renderOptgroup(option, index, this.isOptionHidden(option));
      }
      return this.renderOption(option, index, this.isOptionHidden(option));
    });

    const hasVisibleOption = this.options.some(
      option => !this.isOptionHidden(option)
    );

    this._allOptionsAreHidden =
      this.searchable && this._searchTerm && !hasVisibleOption;

    return options;
  }

  renderSelectHeader() {
    if (!this.multiple || this.multipleHideSelect || this._searchTerm) {
      return nothing;
    }

    const options = this._getOptionsRendered();
    const someSelected = options.some(option => option.selected);
    const allSelected = options.every(
      option => !option.disabled && option.selected
    );
    const indeterminate = someSelected && !allSelected;

    return html`<kat-option
      id="${this._selectAllOptionId}"
      ?multiple=${true}
      ?indeterminate=${indeterminate}
      ?selected=${allSelected}
      @click=${this.handleSelectAllClick}
      @keydown=${this.handleSelectAllKeydown}
      >${getString('select_all', null, this.locale)}</kat-option
    >`;
  }

  renderSelectFooter() {
    if (!this.multiple || !this.multipleShowApply) {
      return nothing;
    }

    const cancelButton = html`<kat-button
      variant="tertiary"
      id=${this._pendingCancelButtonId}
      @click=${this.handlePendingCancelClick}
      @keydown=${this.handleCommonKeydownEvents}
      >${getString('pending_cancel', null, this.locale)}</kat-button
    >`;

    const applyButtonDisabled = this._pendingOptionSelections.length === 0;
    const applyButton = html`<kat-button
      id=${this._pendingApplyButtonId}
      ?disabled=${applyButtonDisabled}
      @click=${this.handlePendingApplyClick}
      @keydown=${this.handleCommonKeydownEvents}
      >${getString('pending_apply', null, this.locale)}</kat-button
    >`;

    // Buttons wrapped in divs for flexbox, so cancel is always left and apply is always right.
    return html`<div
      class="select-actions"
      part="mobile-emulated-modal-footer dropdown-actions"
    >
      <div>${cancelButton}</div>
      <div>${applyButton}</div>
    </div>`;
  }

  renderNoOptionsMsg() {
    if (!this._allOptionsAreHidden) {
      return nothing;
    }

    return html`
      <kat-option disabled="true">
        <div class="standard-option-content">
          <div class="standard-option-name">
            ${getString('no_options', null, this.locale)}
          </div>
        </div>
      </kat-option>
    `;
  }

  private get _searchInputEmpty() {
    return this._searchTerm?.length > 0;
  }

  private get _searchActionIcon() {
    return this._searchInputEmpty ? 'close' : 'search';
  }

  private get _searchActionAriaLabel() {
    return this._searchInputEmpty
      ? getString('clear', null, this.locale)
      : getString('search', null, this.locale);
  }

  renderSearchBar() {
    if (!this.searchable) {
      return nothing;
    }

    if (!this.expanded) {
      return nothing;
    }

    // preventDefault() on `mousedown` prevents Safari from triggering `focusout` event.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus
    return html`
      <div class="search-wrapper" part=${this.getPartSearch(this.expanded)}>
        <input
          id=${this._searchInputId}
          part="search-input"
          class="search-input"
          @input=${this.handleSearchBarInput}
          @keydown=${this.handleSearchBarKeydown}
          aria-label="${getString('search', null, this.locale)}"
        />
        <button
          aria-label=${this._searchActionAriaLabel}
          class="search-icon"
          tabindex=${ifNotNull(this._searchInputEmpty ? null : '-1')}
          @click=${this.handleSearchClearClick}
          @mousedown=${(e: MouseEvent) => e.preventDefault()}
        >
          <kat-icon name=${this._searchActionIcon} size="small"></kat-icon>
        </button>
      </div>
    `;
  }

  private get _renderLabels() {
    const formLabel = this.label
      ? html`
          <kat-label
            unique-id="form-label"
            class="form-label"
            part="dropdown-label"
            text="${this.label}"
            for=${this._uniqueId}
            .tooltipText=${ifNotNull(this.tooltipText)}
            .tooltipPosition=${ifNotNull(this.tooltipPosition)}
            .tooltipTriggerIcon=${ifNotNull(this.tooltipTriggerIcon)}
          ></kat-label>
        `
      : nothing;

    const constraintLabel = this.constraintLabel
      ? html`
          <kat-label
            class="constraint-label"
            variant="constraint"
            emphasis="${ifNotNull(this.constraintEmphasis)}"
            text="${this.constraintLabel}"
            part="dropdown-constraint-label"
          ></kat-label>
        `
      : nothing;

    const stateLabel = this.stateLabel
      ? html`
          <kat-label
            class="state-label"
            variant="constraint"
            text="${this.stateLabel}"
            emphasis="${ifNotNull(this.stateEmphasis)}"
            state="${this.state}"
            part="dropdown-state-label"
          ></kat-label>
        `
      : nothing;
    return { formLabel, constraintLabel, stateLabel };
  }

  private _updateSelectedSummaryOverflow() {
    // Estimating that "+X" will take about 10 pixels + 8px padding on each side.
    const estimatedOverflowWidth = 26;
    const headerRowOverflow = this._shadow('.header-row-overflow');
    let invisibleCounter = 0;

    if (this.multiple && this.values?.length > 0) {
      const availableWidth =
        this._shadow('.header-row-text').getBoundingClientRect().width -
        estimatedOverflowWidth;
      const spans = Array.from(
        this.shadowRoot.querySelectorAll(
          `slot[name="${this._selectedOptionSlotName}"] > span`
        )
      );

      const lastVisibleClass = 'last-visible';
      const hasEllipsisClass = 'has-ellipsis';
      let runningWidth = 0;
      let spanLastVisible;

      // First item is always visible and uses ellipsis
      spans.forEach((span, idx) => {
        const { width } = span.getBoundingClientRect();
        runningWidth += width;
        const invisible = availableWidth < runningWidth;

        // Reset Last Visible
        span.classList.remove(lastVisibleClass, hasEllipsisClass);

        // Keep track of the span so we can remove the comma
        // Could be first invisible one or the last one in the array
        if (!invisible) {
          spanLastVisible = span;
        }

        // First item is always visible, but still needs classes to hide comma
        if (idx > 0) {
          span.classList.toggle('invisible', invisible);
          invisibleCounter += invisible ? 1 : 0;

          // First item is too wide, add ellipsis
        } else if (invisible) {
          span.classList.add(hasEllipsisClass);
        }
      });

      // Remove comma from last visible OR last span in array
      (spanLastVisible || spans[spans.length - 1])?.classList.add(
        lastVisibleClass
      );
    }

    headerRowOverflow.innerHTML =
      invisibleCounter > 0 ? `+${invisibleCounter}` : '';
  }

  /**
   * Retrieves a list of selected options. Used only when multiple is true.
   */
  private filterSelectedOptions(
    options: (KatOptionInput | KatOptgroupInput)[]
  ) {
    return options
      .flatMap(option => {
        if (this.isOptgroupInput(option)) {
          return option.options;
        }

        return option;
      })
      .filter(option => this.values.includes(option.value));
  }

  /**
   * Retrives the single selected option. Used only when multiple is false.
   */
  private filterSelectedOption(options: (KatOptionInput | KatOptgroupInput)[]) {
    return options
      .flatMap(option => {
        if (this.isOptgroupInput(option)) {
          return option.options;
        }

        return option;
      })
      .filter(option => option.value === this.value);
  }

  private renderSelectedSummary() {
    // Display cached selections while there are pending options
    if (this._pendingOptionSelections.length !== 0) {
      return this._lastSelectedSummary;
    }

    const suffix = this.multiple ? html`<i>, </i>` : nothing;

    // Based on the options parameter as current options may not be rendered yet
    if (this._hasSlottedOptions()) {
      const optionsSelected = this._getOptionsSelected();
      const optionsSelectedSuffix = optionsSelected.length > 1 ? suffix : '';

      this._lastSelectedSummary = optionsSelected.map(option => {
        const text = this.selectionSummaryOverride
          ? this.selectionSummaryOverride(option)
          : getContentOfFirstTextNode(option)?.trim();
        return html`<span>${text}${optionsSelectedSuffix}</span>`;
      });
    } else if (this.multiple && this.values) {
      this._lastSelectedSummary = this.filterSelectedOptions(this.options).map(
        option => {
          const text = this.selectionSummaryOverride
            ? this.selectionSummaryOverride(option)
            : option.name;
          return html`<span>${text}${suffix}</span>`;
        }
      );
    } else if (this.value) {
      const selected = this.filterSelectedOption(this.options);

      if (selected.length > 1) {
        console.warn('Dropdown contains multiple options with the same value.');
      }

      if (selected.length > 0) {
        const text = this.selectionSummaryOverride
          ? this.selectionSummaryOverride(selected[0])
          : selected[0].name;
        this._lastSelectedSummary = [html`${text}`];
      }
    } else {
      this._lastSelectedSummary = [];
    }

    return this._lastSelectedSummary;
  }

  render() {
    // Pass multiple to slotted options
    if (this._hasSlottedOptions()) {
      this._getSelectablesRendered().forEach(
        option => (option.multiple = this.multiple)
      );
    }

    this.calculateGroupClasses();

    const { formLabel, constraintLabel, stateLabel } = this._renderLabels;
    const showClearButton =
      this.showClear &&
      ((this.multiple && !this.multipleShowApply && this.values?.length > 0) ||
        (!this.multiple && this.value));

    const clearButton = showClearButton
      ? html`<button
          type="button"
          part="clear-btn"
          @click=${this.handleHeaderClearClick}
          @keydown=${this.handlerHeaderClearKeydown}
          aria-label=${getString('clear', null, this.locale)}
        >
          <kat-icon name="close" size="tiny"></kat-icon>
        </button>`
      : nothing;

    const chevronName = `chevron-${this.expanded ? 'up' : 'down'}`;

    // Full selection is rendered via title attribute
    const renderedSelectedTitle = this._getOptionsSelected()
      .map(option => getContentOfFirstTextNode(option)?.trim())
      .join(', ');

    const renderedOptions = this.renderOptions();
    const renderedSelectedSummary = this.renderSelectedSummary();
    const hasSelectedOptions = renderedSelectedSummary.length > 0;

    const selectionTextClasses = {
      ['selection-text']: true,
      ['has-rich-selection-label']: this.richSelectionLabel,
      hidden: !hasSelectedOptions,
    };

    const placeholderTextClasses = {
      ['placeholder-text']: true,
      hidden: hasSelectedOptions,
    };

    const headerRowTextClasses = {
      ['header-row-text']: true,
      placeholder: !hasSelectedOptions,
      value: hasSelectedOptions,
    };

    return html`
      ${formLabel}
      <div
        class="${this._rootContainerClassname}"
        aria-labelledby="${ifNotNull(
          formLabel === nothing ? null : 'form-label'
        )}"
        aria-label=${ifNotNull(this.katAriaLabel)}
      >
        <div
          id=${this._uniqueId}
          class="select-header"
          part="dropdown-header"
          title=${renderedSelectedTitle}
          tabindex=${ifNotNull(this.disabled ? null : '0')}
          @click=${this.handleHeaderClick}
          @keydown=${this.handleHeaderKeydown}
        >
          <div class="header-row">
            <div class="${classMap(headerRowTextClasses)}">
              <div class="${classMap(selectionTextClasses)}">
                <slot name="${this._selectedOptionSlotName}"
                  >${renderedSelectedSummary}</slot
                >
              </div>
              <div class="${classMap(placeholderTextClasses)}">
                <slot name="placeholder">${this.placeholder}</slot>
              </div>
              <div class="header-row-overflow"></div>
            </div>
            ${clearButton}
            <div class="indicator">
              <kat-icon name="${chevronName}" size="small"></kat-icon>
            </div>
          </div>
        </div>
        <span class="ring"></span>
        <div part="${super.getPartMask(this.expanded)}">
          <div
            class="select-options"
            part="${super.getPartWrapper(this.expanded)} dropdown-options"
          >
            ${this.renderMobileHeader()}${this.renderSearchBar()}
            <div
              class="option-inner-container"
              part="${super.getPartContainer(this.expanded)}"
            >
              <div
                @click=${this.handleOptionClick}
                @keydown=${this.handleOptionKeydown}
                class="option-inner-content"
                part="${super.getPartContent(this.expanded)}"
                tabindex="-1"
                style="${ifNotNull(
                  this.maxHeight
                    ? 'max-height:' + this.maxHeight + ';overflow:auto;'
                    : null
                )}"
              >
                <slot name="select-header">${this.renderSelectHeader()}</slot>
                <slot
                  role="listbox"
                  aria-multiselectable=${ifNotNull(
                    this.multiple ? 'true' : null
                  )}
                  @slotchange=${this.handleSlotChange}
                  >${renderedOptions}</slot
                >
                ${this.renderNoOptionsMsg()}
              </div>
              <slot name="select-footer">${this.renderSelectFooter()}</slot>
            </div>
          </div>
        </div>
        <div class="metadata">${constraintLabel} ${stateLabel}</div>
      </div>
    `;
  }

  // #endregion
}
