import { KatLitFilteredElement, register } from '../../shared/base';
import { KatPageHeader } from '.';

/**
 * @component {kat-page-header-utility-actions} KatPageHeaderUtilityActions Component used to place and filter utility actions within the page header component.
 */

@register(KatPageHeader.slotMapping.utilityActions.name)
export class KatPageHeaderUtilityActions extends KatLitFilteredElement {
  constructor() {
    super(
      KatPageHeader.slotMapping.utilityActions.name,
      KatPageHeader.slotMapping.utilityActions.allowed
    );
  }
}
