import { KatLitFilteredElement, register } from '../../shared/base';
import { KatPageHeader } from './';

/**
 * @component {kat-page-header-alerts} KatPageHeaderAlerts Component used to place and filter alerts within the page header component.
 */
@register(KatPageHeader.slotMapping.alerts.name)
export class KatPageHeaderAlerts extends KatLitFilteredElement {
  constructor() {
    super(
      KatPageHeader.slotMapping.alerts.name,
      KatPageHeader.slotMapping.alerts.allowed
    );
  }
}
