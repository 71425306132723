import { KatLitFilteredElement, register } from '../../shared/base';
import { KatPageHeader } from '.';

/**
 * @component {kat-page-header-page-actions} KatPageHeaderPageActions Component used to place and filter page actions within the page header component.
 */

@register(KatPageHeader.slotMapping.pageActions.name)
export class KatPageHeaderPageActions extends KatLitFilteredElement {
  constructor() {
    super(
      KatPageHeader.slotMapping.pageActions.name,
      KatPageHeader.slotMapping.pageActions.allowed
    );
  }
}
