import {
  KatButton,
  KatColumn,
  KatDatePicker,
  KatDateRangePicker,
  KatInput,
  KatLayout,
  KatList,
  KatModal,
  KatPageHeader,
  KatRow,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableFooter,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import React from "react";
import { Link } from "react-router-dom";
import { LABEL, MESSAGE } from "src/constants/commonConstants";

const MockData = [
  {
    asinName: "123",
    quantity: "73kg",
    sellingDate: "20-12-1727",
    farmerName: "Example Farmer Name 1",
    phoneNumber: "576890807",
    intentRaisingDate: "4-1-1234",
  },
];

export default function ApprovedIntents() {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showNoShowModal, setShowNoShowModal] = React.useState(false);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [showReceiveModal, setShowReceiveModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({
    asinName: "",
    quantity: "",
    sellingDate: "",
    farmerName: "",
    phoneNumber: "",
    intentRaisingDate: "",
  });

  const editModalHandler = (intentRow: any) => {
    setShowEditModal(true);
    setModalData(intentRow);
  };

  const rejectModalHandler = (intentRow: any) => {
    setShowRejectModal(true);
    setModalData(intentRow);
  };

  const receiveModalHandler = (intentRow: any) => {
    setShowReceiveModal(true);
    setModalData(intentRow);
  };

  const noShowHandler = (approvedIntentRow: any) => {
    setShowNoShowModal(true);
    setModalData(approvedIntentRow);
  };

  return (
    <>
      {/* Edit Modal */}
      <KatModal
        className="kat-modal"
        onClose={() => {
          setShowEditModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showEditModal}
        data-testid="edit-modal"
      >
        <Link to={""} onClick={() => setShowEditModal(false)} data-testid="back-button">
          <h2>{"←" + LABEL.BACK}</h2>
        </Link>
        <ul className="modal-list" data-testid="edit-modal-content">
          <li data-testid="asin-name">{LABEL.ASIN_NAME} - {modalData.asinName}</li>
          <li data-testid="farmer-name">{LABEL.FARMER_NAME} - {modalData.farmerName}</li>
          <li data-testid="quantity">{LABEL.QUANTITY} - {modalData.quantity}</li>
          <li data-testid="selling-date">{LABEL.SELLING_DATE} - {modalData.sellingDate}</li>
          <div className="modal-input-group">
            <KatInput
              type="number"
              min={1}
              max={LABEL.QUANTITY}
              placeholder={LABEL.QUANTITY}
              data-testid="quantity-input"
            />
            <KatDatePicker className="pb-3" locale="en-US" data-testid="date-picker" />
          </div>
        </ul>
        <div slot="footer">
          <KatButton label={LABEL.EDIT} data-testid="edit-button" />
        </div>
      </KatModal>

      {/* Reject Modal */}
      <KatModal
        onClose={() => {
          setShowRejectModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showRejectModal}
        data-testid="reject-modal"
      >
        <Link className="mt-2 mb-2" to={""} onClick={() => setShowRejectModal(false)} data-testid="reject-back-button">
          <h2>{"←" + LABEL.BACK}</h2>
        </Link>
        <KatList variant="unordered" className="modal-content" data-testid="reject-modal-content">
          <li data-testid="reject-asin-name">{LABEL.ASIN_NAME} - {modalData.asinName}</li>
          <li data-testid="reject-farmer-name">{LABEL.FARMER_NAME} - {modalData.farmerName}</li>
          <li data-testid="reject-quantity">{LABEL.QUANTITY} - {modalData.quantity}</li>
          <li data-testid="reject-selling-date">{LABEL.SELLING_DATE} - {modalData.sellingDate}</li>
        </KatList>
        <KatInput
          type="text"
          label={LABEL.REASON_FOR_REJECTION}
          placeholder={LABEL.REASON}
          className="mt-2"
          data-testid="reject-reason-input"
        />
        <div slot="footer">
          <KatButton label="Reject" data-testid="reject-button" />
        </div>
      </KatModal>

      {/* Receive Modal */}
      <KatModal
        onClose={() => {
          setShowReceiveModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showReceiveModal}
        data-testid="receive-modal"
      >
        <Link className="mt-2 mb-2" to={""} onClick={() => setShowReceiveModal(false)} data-testid="receive-back-button">
          <h2>{"←" + LABEL.BACK}</h2>
        </Link>
        <KatInput
          type="number"
          label={LABEL.QUANTITY_RECIEVE}
          placeholder={LABEL.QUANTITY}
          className="mt-2"
          data-testid="receive-quantity-input"
        />
        <div slot="footer">
          <KatButton label={LABEL.RECIEVE} data-testid="receive-button" />
        </div>
      </KatModal>

      {/* No Show Modal */}
      <KatModal
        onClose={() => {
          setShowNoShowModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showNoShowModal}
        data-testid="no-show-modal"
      >
        <div>{MESSAGE.NO_SHOW_MESSAGE}</div>
        <div slot="footer">
          <KatButton label={LABEL.NO_SHOW} data-testid="no-show-button" />
        </div>
      </KatModal>

      {/* Main Layout */}
      <KatLayout even>
        <div slot="main" data-testid="main-layout">
          <KatInput
            type="text"
            label={LABEL.SEARCH_ASINS}
            placeholder={LABEL.ASIN_NAME}
            data-testid="search-input"
          />
        </div>
        <div slot="right" data-testid="date-range-picker">
          <KatDateRangePicker
            locale="en-US"
            startLabel={LABEL.FROM}
            endLabel={LABEL.TO}
            data-testid="date-range-picker-input"
          />
        </div>
      </KatLayout>

      {/* Table */}
      <KatRow className="align-items-end mt-4 overflow-visible" data-testid="asin-table-row">
        <KatColumn sm={12}>
          <KatTable>
            <KatTableHead data-testid="asin-table-head">
              <KatTableRow>
                <KatTableCell>{LABEL.ASIN_NAME}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.APPROVED_QUANTITY}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.SELLING_DATE}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.FARMER_NAME}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.PHONE_NUMBER}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.EDIT_REJECT_NO_SHOW}</KatTableCell>
              </KatTableRow>
            </KatTableHead>

            <KatTableBody data-testid="asin-table-body">
              {MockData.map((item, index) => (
                <KatTableRow key={index} data-testid={`asin-row-${index}`}>
                  <KatTableCell>{item.asinName}</KatTableCell>
                  <KatTableCell className="text-center">{item.quantity}</KatTableCell>
                  <KatTableCell className="text-center">{item.sellingDate}</KatTableCell>
                  <KatTableCell className="text-center">{item.farmerName}</KatTableCell>
                  <KatTableCell className="text-center">{item.phoneNumber}</KatTableCell>
                  <KatTableCell className="text-center">
                    <Link to={"#"} onClick={() => editModalHandler(item)} data-testid={`edit-link-${index}`}>
                      Edit
                    </Link>
                    {" / "}
                    <Link to={"#"} onClick={() => rejectModalHandler(item)} data-testid={`reject-link-${index}`}>
                      Reject
                    </Link>
                    {" / "}
                    <Link to={"#"} onClick={() => receiveModalHandler(item)} data-testid={`receive-link-${index}`}>
                      Receive
                    </Link>
                    {" / "}
                    <Link to={"#"} onClick={() => noShowHandler(item)} data-testid={`no-show-link-${index}`}>
                      No Show
                    </Link>
                  </KatTableCell>
                </KatTableRow>
              ))}
            </KatTableBody>
          </KatTable>
          <KatTableFooter className="p-2 h-100" data-testid="asin-table-footer">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3">{MockData.length} Results</div>
            </section>
          </KatTableFooter>
        </KatColumn>
      </KatRow>
    </>
  );
}
