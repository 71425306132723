import { KatDropdown, KatPageHeader } from "@amzn/katal-react";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AppContainer from "src/components/AppContainer";
import { ShadowBox } from "src/components/common/ShadowBox";
import { LABEL } from "src/constants/commonConstants";
import {
  ROUTECONSTANTS_TO_NAME,
  ROUTENAME,
} from "src/constants/routeConstants";

export default function FarmerManagement() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const getHeaderFromUrl = (): string => {
    console.log(currentUrl);
    return ROUTECONSTANTS_TO_NAME(currentUrl);
  };
  return (
    <AppContainer
      parentKey={ROUTENAME.FARMER_INTENT}
      childKey={ROUTENAME.FARMER_INTENT}
    >
      <ShadowBox>
        <div className="flex flex-wrap justify-content-between" >
          <KatPageHeader header={getHeaderFromUrl()}></KatPageHeader>
            <KatDropdown
              className="cc-dropdown"
              label={LABEL.SELECT_CC}
              value={LABEL.SELECT_CC}
              placeholder={LABEL.SHOW_CC_LIST}
              options={[
                { name: "Manchar | CBO1", value: "Manchar | CBO1" },
                { name: "Nashik | CIS1", value: "Nashik | CIS1" },
                { name: "Kunjirwadi | CRA1", value: "Kunjirwadi | CRA1" },
                { name: "Tembhurni | CIN2", value: "Tembhurni | CIN2" },
                { name: "Otur | COT1", value: "Otur | COT1" },
                { name: "Devagad | CNA1", value: "Devagad | CNA1" },
                { name: "Tamsabad | CDL1", value: "Tamsabad | CDL1" },
                { name: "Paota | CJA1", value: "Paota | CJA1" },
                { name: "Solan | CDL2", value: "Solan | CDL1" },
              ]}
            ></KatDropdown>
          {/* </div> */}
        </div>
        <Outlet />
      </ShadowBox>
    </AppContainer>
  );
}
