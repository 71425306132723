import _map from "lodash/map";
import _values from "lodash/values";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import CropFilter from "./CropFilter";
import CropDataTable from "./CropTable";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import AppContainer from "src/components/AppContainer";
import * as cropActions from "src/actions/cropActions";
import * as modalActions from "src/actions/modalActions";
import { LABEL, PAGETITLE } from "src/constants/commonConstants";
import { KatButton, KatColumn, KatRow } from "@amzn/katal-react";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";
import { cropFilter } from "src/helper";

interface CropDataTableProps {
  cropList: any;
  fetchCrops: () => void;
  editCrop: (data: any) => void;
  showModal: (data: any) => void;
  updateCrop: (data: any) => void;
  deleteCrop: (cropId: string) => void;
}

export const CropPage = ({
  cropList,
  editCrop,
  showModal,
  fetchCrops,
  deleteCrop,
  updateCrop,
}: CropDataTableProps) => {
  const [filterCriteria, setfilterCriteria] = React.useState({
    status: "",
    languageCode: "",
  });
  
  const [filterData, setFilterData] = React.useState(cropList);

  useEffect(() => {
    handlerFilter();
  }, [cropList]);

  const handlerFilter = () => {
    const filterCropList = cropFilter({ filterCriteria, cropList });
    setFilterData(filterCropList);
  };

  useEffect(() => {
    fetchCrops();
    editCrop({});
  }, []);

  return (
    <AppContainer
      parentKey={ROUTENAME.CROP_MANAGEMENT}
      childKey={ROUTENAME.CROP_MANAGEMENT_CROP}
    >
      <section>
        <KatRow className="align-items-end justify-content-between mb-4">
          <KatColumn sm={6}>
            <h1>{PAGETITLE.CROP}</h1>
          </KatColumn>
          <KatColumn sm={2}>
            <Link to={ROUTECONSTANTS.CROP_MANAGEMENT_CROP_ADD}>
              <KatButton
                variant="primary"
                label={"+" + LABEL.ADD_NEW_CROP}
                className="w-100"
              />
            </Link>
          </KatColumn>
        </KatRow>
        <CropFilter {...{ filterCriteria, handlerFilter, setfilterCriteria }} />
        <CropDataTable
          cropList={filterData}
          showModal={showModal}
          deleteCrop={deleteCrop}
          updateCrop={updateCrop}
          editCrop={editCrop}
        />
      </section>
    </AppContainer>
  );
};

interface stateType {
  crop: {
    cropList: [
      {
        id: string;
        version: string;
        cropName: string;
        CropStatus: string;
        cropImgUrl: string;
        languageCode: string;
      }
    ];
  };
}

export const mapStateToProps = (state: stateType) => ({
  cropList: state.crop.cropList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCrops: () => {
    dispatch(cropActions.fetchCrops());
  },
  deleteCrop: (cropId: string) => {
    dispatch(cropActions.deleteCrop(cropId));
  },
  updateCrop: (data: any) => {
    dispatch(cropActions.updateCrop(data));
  },
  editCrop: (data: any) => {
    dispatch(cropActions.editCrop(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CropPage);