import { html, property } from 'lit-element';
import { nothing } from 'lit-html';

import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { checkSlots } from '../../shared/slot-utils';
import styles from './layout.lit.scss';

/**
 * @component {kat-layout} KatLayout The layout component provides several pre-built responsive layouts for web apps to standardize and simplify app creation. The layout divides the page into a set of columns that can contain any content.
 * @guideline Do Stack layouts to create more complex page layout.
 * @guideline Do Test your content at all viewport sizes to ensure it responds in an appropriate manner.
 * @guideline Don't Nest layouts inside each other. If need more detailed layout controls, use Katal’s built-in CSS grid.
 * @status Production
 * @theme flo
 * @slot main Main content area is always filled.
 * @slot right Add right column by adding content to the right slot.
 * @slot left Add left column by adding content to the left slot.
 * @example SingleColumn {"content":"<div slot=\"main\">This is the main</div>"}
 * @example TwoColumnLeft {"content":"<div slot=\"main\">This is the main</div> <div slot=\"left\">This is the left</div>"}
 * @example TwoColumnRight {"content":"<div slot=\"main\">This is the main</div> <div slot=\"right\">This is the right</div>"}
 * @example ThreeColumn {"content":"<div slot=\"main\">This is the main</div> <div slot=\"right\">This is the right</div>  <div slot=\"left\">This is the left</div>"}
 */
@register('kat-layout')
export class KatLayout extends KatLitElement {
  /** Specifies even or weighted. Defaults to false with weighted spacing 25% 50% 25% split. If set to true, the content areas will be split evenly 33% 33% 33%. */
  @property()
  even?: boolean;

  /** If left column defined, allow it to collapse to the width of its content. */
  @property({ attribute: 'collapse-left' })
  collapseLeft? = false;

  /** If right column defined, allow it to collapse to the width of its content. */
  @property({ attribute: 'collapse-right' })
  collapseRight? = false;

  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    const result = checkSlots(this);
    const hasRightAside = result.right;
    const hasLeftAside = result.left;

    let area = '';
    if (hasRightAside && hasLeftAside) {
      area = 'layout_all';
    } else if (hasRightAside) {
      area = 'layout_right';
    } else if (hasLeftAside) {
      area = 'layout_left';
    } else {
      area = 'layout_main';
    }

    return html` <div class=${area}>
      ${hasLeftAside
        ? html` <aside class="left"><slot name="left"></slot></aside>`
        : nothing}
      <main class="main"><slot name="main"></slot></main>
      ${hasRightAside
        ? html` <aside class="right"><slot name="right"></slot></aside>`
        : nothing}
    </div>`;
  }
}
