import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { checkSlots } from '../../shared/slot-utils';
import styles from './page-header.lit.scss';

/**
 * @component {kat-page-header} KatPageHeader Standardizes top-of-page information including the location and styling of alerts, the page title, and other common elements. Usage is recommended, but not required. If you don’t utilize this component, you should use ensure your page still has an H1 header and other, similar, elements in roughly the same order.
 * @example PageHeader {"header": "My Page", "subheader": "This is my custom page.  It allows you to manage things.", "content": "<kat-page-header-alerts><kat-alert variant=\"danger\" id=\"alert-0\">My Alert 0</kat-alert><kat-alert>My Alert 1</kat-alert></kat-page-header-alerts><kat-page-header-utility-links><kat-link href=\"#\">Link 1</kat-link><kat-link href=\"#\">Link 2</kat-link></kat-page-header-utility-links><kat-page-header-utility-actions><kat-button><kat-icon name=\"notifications\" size=\"small\"></kat-icon></kat-button><kat-button><kat-icon name=\"settings\" size=\"small\"></kat-icon></kat-button></kat-page-header-utility-actions><p>Introductory or explanatory body content. We expect this to be plaintext, but will not actively restrict content.</p><kat-page-header-page-actions><kat-button variant=\"tertiary\">Page Action</kat-button><kat-button variant=\"secondary\">Page Action</kat-button><kat-button variant=\"primary\">Page Action</kat-button></kat-page-header-page-actions>"}
 * @subcomponent ./page-header-alerts.ts
 * @subcomponent ./page-header-page-actions.ts
 * @subcomponent ./page-header-utility-links.ts
 * @subcomponent ./page-header-utility-actions.ts
 * @a11y {keyboard}
 * @a11y {contrast}
 * @a11y {sr}
 */
@register('kat-page-header')
export class KatPageHeader extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  // Mapping of a component/slot name to their allowed components
  static slotMapping = {
    alerts: {
      name: 'kat-page-header-alerts',
      allowed: ['kat-alert'],
    },
    pageActions: {
      name: 'kat-page-header-page-actions',
      allowed: ['kat-button', 'kat-link'],
    },
    utilityActions: {
      name: 'kat-page-header-utility-actions',
      allowed: ['kat-button', 'kat-link'],
    },
    utilityLinks: {
      name: 'kat-page-header-utility-links',
      allowed: ['kat-link'],
    },
  };

  /** Header that will be displayed. */
  @property()
  header?: string;

  /** Subheader that will be displayed. */
  @property()
  subheader?: string;

  private getRenderedHeader(slots: Record<string, boolean>) {
    const hasHeader = !!this.header;
    const hasUtilityLinks = slots[KatPageHeader.slotMapping.utilityLinks.name];
    const hasUtilityActions =
      slots[KatPageHeader.slotMapping.utilityActions.name];

    if (!hasHeader && !hasUtilityLinks && !hasUtilityActions) {
      return nothing;
    }

    const renderedHeader = hasHeader ? html`<h1>${this.header}</h1>` : nothing;

    const renderedUtilityLinks = hasUtilityLinks
      ? html`<div class="utility-links">
          <slot name=${KatPageHeader.slotMapping.utilityLinks.name}></slot>
        </div>`
      : nothing;

    const renderedUtilityActions = hasUtilityActions
      ? html`<div class="utility-actions">
          <slot name=${KatPageHeader.slotMapping.utilityActions.name}></slot>
        </div>`
      : nothing;

    return html`<div class="header">
      ${renderedHeader} ${renderedUtilityLinks} ${renderedUtilityActions}
    </div>`;
  }

  render() {
    const slots = checkSlots(this);

    const renderedAlerts = slots[KatPageHeader.slotMapping.alerts.name]
      ? html`<div class="alerts">
          <slot name=${KatPageHeader.slotMapping.alerts.name}></slot>
        </div>`
      : nothing;

    const renderedHeader = this.getRenderedHeader(slots);

    const renderedSubheader = this.subheader
      ? html`<h2>${this.subheader}</h2>`
      : nothing;

    const renderedPageActions = slots[
      KatPageHeader.slotMapping.pageActions.name
    ]
      ? html`<div class="page-actions">
          <slot name=${KatPageHeader.slotMapping.pageActions.name}></slot>
        </div>`
      : nothing;

    return html`<div class="wrapper">
      ${renderedAlerts} ${renderedHeader} ${renderedSubheader}

      <slot></slot>

      ${renderedPageActions}
    </div>`;
  }
}
