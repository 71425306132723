import {
  KatColumn,
  KatGrid,
  KatGridItem,
  KatModal,
  KatRow,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableFooter,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { LABEL } from "src/constants/commonConstants";
import { FarmerModalData } from "src/types";

interface propsInterface {
  farmerModalData: FarmerModalData;
  setFarmerModalData: Dispatch<SetStateAction<FarmerModalData>>;
  showFarmerProfileModal: boolean;
  setShowFarmerProfileModal: Dispatch<SetStateAction<boolean>>;
}

const MockData = [
  {
    intentsRaised: "15",
    intentsApproved: "5",
    intentsReceived: "16",
    intentsNoShow: "2",
  },
  {
    intentsRaised: "10",
    intentsApproved: "7",
    intentsReceived: "10",
    intentsNoShow: "0",
  },
  {
    intentsRaised: "8",
    intentsApproved: "3",
    intentsReceived: "9",
    intentsNoShow: "1",
  },
  {
    intentsRaised: "20",
    intentsApproved: "12",
    intentsReceived: "22",
    intentsNoShow: "3",
  },
  {
    intentsRaised: "6",
    intentsApproved: "2",
    intentsReceived: "7",
    intentsNoShow: "0",
  },
  {
    intentsRaised: "18",
    intentsApproved: "10",
    intentsReceived: "19",
    intentsNoShow: "1",
  },
  {
    intentsRaised: "9",
    intentsApproved: "4",
    intentsReceived: "11",
    intentsNoShow: "2",
  },
  {
    intentsRaised: "12",
    intentsApproved: "6",
    intentsReceived: "13",
    intentsNoShow: "1",
  },
  {
    intentsRaised: "11",
    intentsApproved: "5",
    intentsReceived: "12",
    intentsNoShow: "1",
  },
  {
    intentsRaised: "14",
    intentsApproved: "7",
    intentsReceived: "15",
    intentsNoShow: "2",
  },
];

export const FarmerProfileModal = ({
  farmerModalData,
  setFarmerModalData,
  showFarmerProfileModal,
  setShowFarmerProfileModal,
}: propsInterface) => {
  const modalCloseHandler = () => {
    setShowFarmerProfileModal(false);
    setFarmerModalData({
      farmerid: "",
      farmerName: "",
      phoneNumber: "",
      vendorCode: "",
      status: "",
    });
  };
  return (
    <KatModal
      visible={showFarmerProfileModal}
      noCloseIcon
      onClose={modalCloseHandler}
    >
      <h2>
        {" "}
        <Link to="#" onClick={modalCloseHandler}>
          {"←" + LABEL.BACK}
        </Link>
      </h2>
      <ul>
        <li>
          {LABEL.FARMER_NAME} : {farmerModalData.farmerName}
        </li>
        <li>
          {LABEL.PHONE_NUMBER} : {farmerModalData.phoneNumber}
        </li>
        <li>
          {LABEL.VENDOR_ID} : {farmerModalData.vendorCode}
        </li>
      </ul>
      <KatGrid colsXxl={2} colsXl={2} colsM={1} colsS={1}>
        <KatGridItem>
          {LABEL.NUMBER_OF_NO_SHOWS} : {Math.floor(Math.random() * 10)}
        </KatGridItem>
        <KatGridItem>
          {LABEL.NUMBER_OF_INTENTS_RAISED} : {Math.floor(Math.random() * 10)}
        </KatGridItem>
        <KatGridItem>
          {LABEL.NUMBER_OF_INTENTS_APPROVED} : {Math.floor(Math.random() * 10)}
        </KatGridItem>
        <KatGridItem>
          {LABEL.NUMBER_OF_INTENTS_RECEIVED} : {Math.floor(Math.random() * 10)}
        </KatGridItem>
      </KatGrid>

      <KatRow className="align-items-end mt-4 overflow-visible">
        <KatColumn sm={12}>
          <KatTable>
            <KatTableHead>
              <KatTableRow>
                <KatTableCell>{LABEL.NUMBER_OF_INTENTS_RAISED}</KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.NUMBER_OF_INTENTS_APPROVED}
                </KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.NUMBER_OF_INTENTS_RECEIVED}
                </KatTableCell>
                <KatTableCell className="text-center">
                  {LABEL.NUMBER_OF_NO_SHOWS}
                </KatTableCell>
              </KatTableRow>
            </KatTableHead>
            <KatTableBody>
              {MockData.map((item) => {
                return (
                  <KatTableRow>
                    <KatTableCell>{item.intentsRaised}</KatTableCell>
                    <KatTableCell className="text-center">
                      {item.intentsApproved}
                    </KatTableCell>
                    <KatTableCell>{item.intentsReceived}</KatTableCell>
                    <KatTableCell>{item.intentsNoShow}</KatTableCell>
                  </KatTableRow>
                );
              })}
            </KatTableBody>
          </KatTable>
          <KatTableFooter className="p-2 h-100">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3">{MockData.length} Results</div>
            </section>
          </KatTableFooter>
        </KatColumn>
      </KatRow>
    </KatModal>
  );
};
