

import {
  KatButton,
  KatColumn,
  KatDatePicker,
  KatDateRangePicker,
  KatIcon,
  KatInput,
  KatLayout,
  KatList,
  KatModal,
  KatPageHeader,
  KatPagination,
  KatRow,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableFooter,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import React from "react";
import { Link } from "react-router-dom";
import { LABEL, PAGE_SIZE } from "src/constants/commonConstants";

const MockData = [
  {
    asinName: "123",
    quantity: "73kg",
    sellingDate: "20-12-1727",
    farmerName: "Example Farmer Name 1",
    phoneNumber: "576890807",
    intentRaisingDate: "4-1-1234",
  },
];

export default function OpenIntents() {
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({
    asinName: "",
    quantity: "",
    sellingDate: "",
    farmerName: "",
    phoneNumber: "",
    intentRaisingDate: "",
  });

  const approveModalHandler = (intentRow:any) => {
    setShowApproveModal(true);
    setModalData(intentRow);
  };

  const rejectModalHandler = (intentRow:any) => {
    setShowRejectModal(true);
    setModalData(intentRow);
  };

  return (
    <>
      <KatModal
        data-testid="approve-modal"
        className="kat-modal"
        onClose={() => {
          setShowApproveModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showApproveModal}
      >
        <Link to={""} onClick={() => setShowApproveModal(false)}>
          <h2>{"←" + LABEL.BACK}</h2>
        </Link>
        <ul className="modal-list">
          <li data-testid="asin-name">{LABEL.ASIN_NAME} - {modalData.asinName}</li>
          <li data-testid="farmer-name">{LABEL.FARMER_NAME} - {modalData.farmerName}</li>
          <li data-testid="quantity">{LABEL.QUANTITY} - {modalData.quantity}</li>
          <li data-testid="selling-date">{LABEL.SELLING_DATE} - {modalData.sellingDate}</li>
          <div className="modal-input-group">
            <KatInput
              data-testid="approve-quantity-input"
              type="number"
              min={1}
              placeholder={LABEL.QUANTITY}
            />
            <KatDatePicker className="test pb-3" locale="en-US" data-testid="approve-date-picker"></KatDatePicker>
          </div>
        </ul>
        <div slot="footer">
          <KatButton label="Approve" data-testid="approve-button"></KatButton>
        </div>
      </KatModal>
      
      <KatModal
        data-testid="reject-modal"
        onClose={() => {
          setShowRejectModal(false);
          setModalData({
            asinName: "",
            quantity: "",
            sellingDate: "",
            farmerName: "",
            phoneNumber: "",
            intentRaisingDate: "",
          });
        }}
        noCloseIcon
        visible={showRejectModal}
      >
        <Link
          className="mt-2 mb-2"
          to={""}
          onClick={() => setShowRejectModal(false)}
        >
          <h2>{"←" + LABEL.BACK}</h2>
        </Link>
        <KatList variant="unordered" className="modal-content">
          <li data-testid="asin-name-reject">{LABEL.ASIN_NAME} - {modalData.asinName}</li>
          <li data-testid="farmer-name-reject">{LABEL.FARMER_NAME} - {modalData.farmerName}</li>
          <li data-testid="quantity-reject">{LABEL.QUANTITY} - {modalData.quantity}</li>
          <li data-testid="selling-date-reject">{LABEL.SELLING_DATE} - {modalData.sellingDate}</li>
        </KatList>
        <KatInput
          type="text"
          label={LABEL.REASON_FOR_REJECTION_OPTIONAL}
          placeholder={LABEL.REASON}
          className="mt-2"
          data-testid="reject-reason-input"
        />
        <div slot="footer">
          <KatButton label="Reject" data-testid="reject-button"></KatButton>
        </div>
      </KatModal>

      <KatLayout even>
        <div slot="main">
          <KatInput
            type="text"
            label={LABEL.SEARCH_ASINS}
            placeholder={LABEL.ASIN_NAME}
            data-testid="search-asins-input"
          />
        </div>
        <div slot="right">
          <KatDateRangePicker
            locale="en-US"
            startLabel={LABEL.FROM}
            endLabel={LABEL.TO}
            data-testid="date-range-picker"
          />
        </div>
      </KatLayout>

      <KatRow className="align-items-end mt-4 overflow-visible">
        <KatColumn sm={12}>
          <KatTable>
            <KatTableHead>
              <KatTableRow>
                <KatTableCell>{LABEL.ASIN_NAME}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.QUANTITY}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.SELLING_DATE}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.FARMER_NAME}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.PHONE_NUMBER}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.INTENT_RAISING_DATE}</KatTableCell>
                <KatTableCell className="text-center">{LABEL.APPROVE_REJECT}</KatTableCell>
              </KatTableRow>
            </KatTableHead>

            <KatTableBody>
              {MockData.map((item, index) => (
                <KatTableRow key={index} data-testid={`asin-table-row-${index}`}>
                  <KatTableCell>{item.asinName}</KatTableCell>
                  <KatTableCell className="text-center">{item.quantity}</KatTableCell>
                  <KatTableCell className="text-center">{item.sellingDate}</KatTableCell>
                  <KatTableCell className="text-center">{item.farmerName}</KatTableCell>
                  <KatTableCell className="text-center">{item.phoneNumber}</KatTableCell>
                  <KatTableCell className="text-center">{item.intentRaisingDate}</KatTableCell>
                  <KatTableCell className="text-center">
                    <Link
                      to="#"
                      onClick={() => approveModalHandler(item)}
                      data-testid={`approve-link-${index}`}
                    >
                      Approve
                    </Link>
                    {" / "}
                    <Link
                      to="#"
                      onClick={() => rejectModalHandler(item)}
                      data-testid={`reject-link-${index}`}
                    >
                      Reject
                    </Link>
                  </KatTableCell>
                </KatTableRow>
              ))}
            </KatTableBody>
          </KatTable>
          <KatTableFooter className="p-2 h-100">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3" data-testid="results-count">{MockData.length} Results</div>
            </section>
          </KatTableFooter>
        </KatColumn>
      </KatRow>
    </>
  );
}
