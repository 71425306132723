import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";

const getRoutesBasedOnRole = (group: string) => {
  let list: string[] = [];

  switch (group) {
    case "Upaj-Developer":
    case "Upaj-Admins":
      list = [
        ROUTENAME.CROP_MANAGEMENT,
        ROUTENAME.CROP_PLAN_MANAGEMENT,
        ROUTENAME.QUERY_MANAGEMENT,
        ROUTENAME.FARMER_INTENT,
        ROUTECONSTANTS.ROOT,
        ROUTECONSTANTS.CROP_MANAGEMENT_CROP,
        ROUTECONSTANTS.CROP_MANAGEMENT_CROP_ADD,
        ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY,
        ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY_ADD,
        ROUTECONSTANTS.CROP_PLAN_MANAGEMENT,
        ROUTECONSTANTS.QUERY_MANAGEMENT_NEW_QUERIES,
        ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES,
        ROUTECONSTANTS.CROP_PLAN_MANAGEMENT_PREVIEW,
        ROUTECONSTANTS.FARMER_INTENT,
        ROUTECONSTANTS.FARMER_INTENT_APPROVED_INTENTS,
        ROUTECONSTANTS.FARMER_INTENT_OPEN_INTENTS,
        ROUTECONSTANTS.FARMER_INTENT_FARMERS,
        ROUTECONSTANTS.FARMER_INTENT_ASINS
      ];
      break;
    case "Upaj-Agronomists": //Non Admin
      list = [
        ROUTENAME.QUERY_MANAGEMENT,
        ROUTECONSTANTS.ROOT,
        ROUTECONSTANTS.QUERY_MANAGEMENT_NEW_QUERIES,
        ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES,
      ];
      break;
  }

  return list;
};

type hasPermissionType = {
  role: string[];
  path: string;
};

export const hasPermission = ({ role, path }: hasPermissionType) => {
  return role.some((group) => {
    const routeList = getRoutesBasedOnRole(group);
    return routeList && routeList.includes(path);
  });
};
