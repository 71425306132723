import { LABEL } from "./commonConstants";

export const ROUTECONSTANTS = {
  ANY: "/*",
  ROOT: "/",
  AUTH: "/auth",
  CROP_MANAGEMENT_CROP: "/crop-management/crop",
  CROP_MANAGEMENT_CROP_ADD: "/crop-management/crop/add",
  CROP_PLAN_MANAGEMENT: "/crop-plan-management",
  CROP_PLAN_MANAGEMENT_PREVIEW: "preview/:planId",
  CROP_MANAGEMENT_VARIETY: "/crop-management/variety",
  CROP_MANAGEMENT_VARIETY_ADD: "/crop-management/variety/add",
  QUERY_MANAGEMENT_NEW_QUERIES: "/query/new-queries",
  QUERY_MANAGEMENT_ACTIVE_QUERIES: "/query/active-queries",
  FARMER_INTENT_APPROVED_INTENTS: "/farmer-intent/approved-intents",
  FARMER_INTENT_FARMERS: "/farmer-intent/allowed-farmers",
  FARMER_INTENT_ASINS: "/farmer-intent/allowed-asins",
  FARMER_INTENT_OPEN_INTENTS: "/farmer-intent/open-intents",
  FARMER_INTENT: "/farmer-intent",
};

export const ROUTENAME = {
  CROP_MANAGEMENT: "crop-management",
  CROP_MANAGEMENT_CROP: "crop",
  CROP_MANAGEMENT_CROP_ADD: "crop/add",
  CROP_PLAN_MANAGEMENT: "crop-plan-management",
  CROP_PLAN_MANAGEMENT_PREVIEW: "preview/:planId",
  CROP_MANAGEMENT_VARIETY: "variety",
  CROP_MANAGEMENT_VARIETY_ADD: "variety/add",
  QUERY_MANAGEMENT: "query",
  QUERY_MANAGEMENT_NEW_QUERIES: "new-queries",
  QUERY_MANAGEMENT_ACTIVE_QUERIES: "active-queries",
  FARMER_INTENT: "farmer-intent",
  FARMER_INTENT_FARMERS: "farmer-intent/farmers",
  FARMER_INTENT_ASINS: "farmer-intent/asins",
  FARMER_INTENT_APPROVED_INTENTS: "approved-intents",
  FARMER_INTENT_OPEN_INTENTS: "open-intents",
};

export const ROUTECONSTANTS_TO_NAME = (route: string) => {
  switch (route) {
    case ROUTECONSTANTS.FARMER_INTENT_APPROVED_INTENTS:
      return LABEL.APPROVED_INTENTS;
    case ROUTECONSTANTS.FARMER_INTENT_FARMERS:
      return LABEL.FARMER_ALLOW_LISTING;
    case ROUTECONSTANTS.FARMER_INTENT_ASINS:
      return LABEL.ASIN_ALLOW_LISTING;
    case ROUTECONSTANTS.FARMER_INTENT_OPEN_INTENTS:
      return LABEL.OPEN_INTENTS;
    case ROUTECONSTANTS.FARMER_INTENT:
      return LABEL.FARMER_INTENT;
    default:
      return "Invalid route header";
  }
};
