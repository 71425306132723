import { ifDefined } from 'lit-html/directives/if-defined';
import { classMap } from 'lit-html/directives/class-map.js';
import { html, internalProperty, property } from 'lit-element';
import {
  Keys,
  KatLitElement,
  register,
  event,
  EventEmitter,
} from '../../shared/base';
import {
  numberOfDaysInMonth,
  addDay,
  daysBeforeFirstOfMonth,
  dateCopy,
  isSameMonth,
  applyDay,
  daysWithinOneYearBefore,
  daysWithinOneYearAfter,
} from '../../shared/base/date-utils';
import {
  daysOfWeekByLocale,
  monthsOfYearByLocale,
  fullDaysOfMonth,
} from '../calendar/date-locale-cache';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './calendar.lit.scss';

const DATE_NOT_SELECTED = -999;
const DAYS_IN_A_WEEK = 7;
const MAX_DAYS_TO_DISPLAY = DAYS_IN_A_WEEK * 6;

/**
 * @component {kat-calendar} KatalCalendar The calendar component allows users to select a date from a graphical calendar view. It is integrated with both the <a href="/components/date-picker/">date picker</a> and <a href="/components/date-range-picker/">date range picker</a> and should not be used on its own.
 * @example USCalendar {"value": "1654196028898", "locale": "en-US"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-calendar')
export class KatCalendar extends KatLitElement {
  /**
   * Accepts timestamp as a string to set a date.
   * @required
   */
  @property({ reflect: false })
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    // Accept timestamp
    const type = typeof value;
    if (type === 'number' || (type === 'string' && value.match(/^[0-9]+$/))) {
      value = new Date(parseInt(value, 10));
    }

    const oldVal = this._value;
    if (value instanceof Date) {
      this._value = dateCopy(value);
      this.current = this._value;
      this.requestUpdate('value', oldVal);
    } else if (!value) {
      this._value = dateCopy(this.today);
      this.current = this._value;
      this.requestUpdate('value', oldVal);
    }
  }

  /** The locale the calendar should be using */
  @property()
  locale = 'en-US';

  /**
   * Sets the function used to determine which dates are disabled. Called for each date rendered in the calendar to
   * determine if the date should be disabled. If this function returns true, the given date will be disabled.
   *
   * @param date a JS Date object representing a calendar date with the _current time_ and returns
   * @returns Boolean representing whether or not the calendar item should be disabled for that date and time.
   */
  @property({ attribute: false })
  isDateDisabled?: (date: Date) => boolean;

  /**
   * Defines a function that is called each time a day is rendered.
   * Passes the date the day represents.
   * Returns a configuration object that contains the date rendering style and accessibility content.
   * Returns null if no specific styling is applicable to the date.
   * style attribute must be one of "color-code-01" | "color-code-02" | "color-code-03" | "color-code-04" | "color-code-05" |
   * "color-code-06" | "color-code-07" | "color-code-08" | "color-code-09" | "color-code-10".
   *
   * Each style must be accompanied with a corresponding accessibility blurb that describes the specifics of the date.
   *
   * E.G. {style: 'color-code-01', ariaLabel: 'Bookings today require payment.'}
   */
  @property({ attribute: false })
  getDateDecorationConfig?: (
    date: Date
  ) => { style: string; ariaLabel: string } | null;

  @internalProperty()
  current = new Date();

  /** Fires when date is chosen. */
  @event('change', true)
  private _change: EventEmitter<{ value: any }>; // TODO: value should always be a concrete type, not sometimes string or sometimes date

  /** Fires when the user hits the escape key. */
  @event('hide')
  private _hide: EventEmitter;

  /** @private */
  @event('pre-click', true)
  private _preclick: EventEmitter<any>;

  /** @private */
  @event('fullblur', true)
  private _fullblur: EventEmitter;

  static get styles() {
    return [baseStyles, styles];
  }

  constructor() {
    super();

    this.today = dateCopy(this.current);

    this._daysShort = daysOfWeekByLocale(this.locale, 'short');
    this._daysLong = daysOfWeekByLocale(this.locale, 'long');
    this._monthsLong = monthsOfYearByLocale(this.locale, 'long');
    this._dayLabelsKey = null;
    this._dayLabels = null;
  }

  onClickDay(node) {
    const day = +node.textContent;
    const isFuture = node.classList.contains('future');
    const isPast = node.classList.contains('past');
    if (node.classList.contains('disabled')) {
      return;
    }

    if (isFuture) {
      this.navigateMonths(1);
      return;
    }
    if (isPast) {
      this.navigateMonths(-1);
      return;
    }

    this.value = applyDay(this.current, day ? day : 1);
    this._change.emit({ value: this.value });
  }

  navigateMonths(direction) {
    const date = applyDay(this.current, 1);
    date.setMonth(date.getMonth() + direction);
    date.setDate(Math.min(this.current.getDate(), numberOfDaysInMonth(date)));
    this.current = date;
  }

  render() {
    const month = this.current.getMonth();
    const year = this.current.getFullYear();
    const prevMonthLabel = this._getMonthLabel(month - 1, year);
    const currentMonth = this._getMonthLabel(month, year);
    const nextMonthLabel = this._getMonthLabel(month + 1, year);

    return html`
      <div class="calendar" @focusout="${this._onFocusOut}">
        <div class="cal-header">
          <button
            type="button"
            class="cal-lft"
            part="calendar-prev-month"
            aria-label="${prevMonthLabel}"
            @click="${this._calendarDirectionClick}"
            @keydown="${this._monthChangeKeyHandler}"
          >
            <kat-icon name="chevron-left" size="small"></kat-icon>
          </button>
          <span
            class="cal-month"
            role="presentation"
            part="calendar-month-name"
          >
            ${currentMonth}
          </span>
          <button
            type="button"
            class="cal-rgt"
            part="calendar-next-month"
            aria-label="${nextMonthLabel}"
            @click="${this._calendarDirectionClick}"
            @keydown="${this._monthChangeKeyHandler}"
          >
            <kat-icon name="chevron-right" size="small"></kat-icon>
          </button>
        </div>
        <div
          class="cal-container"
          @mousedown="${this._mouseDownEventHandler}"
          @click="${this._clickEventHandler}"
          @keydown="${this._keyChangeEventHandler}"
        >
          <table class="cal-body">
            <thead>
              <tr>
                ${this._daysShort.map(
                  (dayofWeek, i) => html`
                    <th class="day-of-week" aria-label="${this._daysLong[i]}">
                      ${dayofWeek}
                    </th>
                  `
                )}
              </tr>
            </thead>
            <tbody>
              ${this._renderDays(prevMonthLabel, nextMonthLabel)}
            </tbody>
          </table>
        </div>
      </div>
    `;
  }

  focusDay() {
    const button = this.shadowRoot.querySelector(
      `.day.on button[data-day='${this.current.getDate()}']`
    );
    if (button) {
      button.focus();
    }
  }

  firstUpdated() {
    const calendar = this.shadowRoot.querySelector('.calendar');
    const events = ['keyup', 'keydown', 'keypressed', 'click'];
    events.forEach(key =>
      calendar.addEventListener(key, e => e.stopImmediatePropagation())
    );
  }

  shouldUpdate(changedProps) {
    if (changedProps.has('locale')) {
      this._daysShort = daysOfWeekByLocale(this.locale, 'short');
      this._daysLong = daysOfWeekByLocale(this.locale, 'long');
      this._monthsLong = monthsOfYearByLocale(this.locale, 'long');
    }

    return true;
  }

  updated() {
    if (this.updateFocus) {
      this.updateFocus = false;
      this.focusDay();
    }
  }

  _renderDays(prevMonth, nextMonth) {
    const dayLabels = this._memoizeDayLabels();
    const weeks = [];
    let weekDay = 0;

    const days = [];
    const daysBeforeFirst = daysBeforeFirstOfMonth(this.current);
    const numberOfDays = numberOfDaysInMonth(this.current);
    const daysAfterLast = MAX_DAYS_TO_DISPLAY - numberOfDays - daysBeforeFirst;
    const dateToday = this.getSelectedDate(this.today, this.current);
    const dateSelected = this.value
      ? this.getSelectedDate(this.value, this.current)
      : DATE_NOT_SELECTED;
    const dateHighlighted = this.current.getDate();

    for (let i = 1; i <= daysBeforeFirst; i++) {
      days.push(
        html`
          <td class="day off past">
            <button
              type="button"
              part="calendar-day-before-${i - 1}"
              aria-label="${prevMonth}"
              tabindex="-1"
            ></button>
          </td>
        `
      );
      ++weekDay;
    }

    for (let dayOfMonth = 1; dayOfMonth <= numberOfDays; dayOfMonth++) {
      const tabIndex = dayOfMonth === dateHighlighted ? 0 : -1;

      const classes = { day: true, on: true };
      classes.today = dayOfMonth === dateToday;
      classes.selected = dayOfMonth === dateSelected;
      classes['last-day'] = dayOfMonth === numberOfDays;
      classes.disabled = false;
      if (this.isDateDisabled) {
        const date = applyDay(this.current, dayOfMonth);
        classes.disabled = this.isDateDisabled(date);
      }

      let ariaLabel;
      let styleName;
      if (!classes.disabled && this.getDateDecorationConfig) {
        const date = applyDay(this.current, dayOfMonth);
        const decorationConfig = this.getDateDecorationConfig(date);
        styleName = decorationConfig ? decorationConfig.style : null;
        ariaLabel = decorationConfig ? decorationConfig.ariaLabel : null;
        classes[styleName] = styleName;
      }

      const buttonClasses = {
        'kat-no-style': true,
        highlighted: dayOfMonth === dateHighlighted,
        [styleName]: styleName,
      };

      const ariaToday = classes.today ? 'date' : undefined;

      days.push(
        html`
          <td class=${classMap(classes)}>
            <button
              type="button"
              part="calendar-day-${dayOfMonth - 1}"
              aria-disabled="${classes.disabled}"
              tabindex="${tabIndex}"
              aria-label=${`${dayLabels[dayOfMonth - 1]}${
                ariaLabel ? '. ' + ariaLabel : ''
              }`}
              aria-current=${ifDefined(ariaToday)}
              aria-pressed="${classes.selected}"
              data-day="${dayOfMonth}"
              class=${classMap(buttonClasses)}
            >
              ${dayOfMonth}
            </button>
          </td>
        `
      );

      if (++weekDay % 7 === 0) {
        weeks.push(
          html`
            <tr>
              ${days.splice(0)}
            </tr>
          `
        );
      }
    }

    for (let i = 1; i <= daysAfterLast; i++) {
      days.push(
        html`
          <td class="day off future">
            <button
              type="button"
              part="calendar-day-after-${i - 1}"
              aria-label="${nextMonth}"
              tabindex="-1"
            ></button>
          </td>
        `
      );

      if (++weekDay % 7 === 0) {
        weeks.push(
          html`
            <tr>
              ${days.splice(0)}
            </tr>
          `
        );
      }
    }

    return weeks;
  }

  _calendarDirectionClick(e) {
    e.stopPropagation();
    const direction = e.currentTarget.classList.contains('cal-rgt') ? 1 : -1;
    this.navigateMonths(direction);
  }

  _mouseDownEventHandler(e) {
    const node = e.target.closest('.day');
    if (!node) return;

    const isDisabled = node.classList.contains('disabled');
    if (isDisabled) {
      e.preventDefault();
    }
  }

  _clickEventHandler(e) {
    e.stopImmediatePropagation();
    this._preclick.emit(e);
    const node = e.target.closest('.day');
    if (node) {
      this.onClickDay(node);
    }
  }

  _keyChangeEventHandler(e) {
    let days;
    switch (e.keyCode) {
      case Keys.ArrowLeft:
        days = -1;
        break;
      case Keys.ArrowRight:
        days = 1;
        break;
      case Keys.ArrowUp:
        days = -7;
        break;
      case Keys.ArrowDown:
        days = 7;
        break;
      case Keys.PageUp:
        days = numberOfDaysInMonth(this.current);
        break;
      case Keys.PageDown: {
        const date = dateCopy(this.current);
        date.setMonth(date.getMonth() - 1);
        days = -numberOfDaysInMonth(date);
        break;
      }
      case Keys.Home:
        days = daysWithinOneYearAfter(this.current);
        break;
      case Keys.End:
        days = -daysWithinOneYearBefore(this.current);
        break;
      case Keys.Escape:
        this._hide.emit();
        return false;
      default:
        return false;
    }

    e.preventDefault();
    e.stopImmediatePropagation();
    this.current = addDay(this.current, days);
    this.updateFocus = true;
    return false;
  }

  _monthChangeKeyHandler(e) {
    if (e.keyCode === Keys.Escape) {
      this._hide.emit();
      return false;
    }
  }

  _onFocusOut(evt) {
    const relatedTarget = evt.relatedTarget;
    const isOffDay = !!evt.target.closest('.day.off');

    if (
      !this.shadowRoot.contains(relatedTarget) &&
      !isOffDay &&
      !this.updateFocus
    ) {
      this._fullblur.emit();
    }
  }

  /**
   * Returns a label to describe a month such as "February 2020" giving a month index and year number.
   * Adjusts month and year if month specified is outside of [0 ... 11] but doesn't handle if the month is outside of [-11 ... 23] (more than one year distant).
   */
  _getMonthLabel(month, fullYear) {
    if (month < 0) {
      month += 12;
      fullYear--;
    }
    if (month > 11) {
      month -= 12;
      fullYear++;
    }
    return this._monthsLong[month] + ' ' + fullYear;
  }

  _memoizeDayLabels() {
    const key =
      this.current.getMonth() +
      '-' +
      this.current.getFullYear() +
      '-' +
      this.locale;
    if (this._dayLabelsKey !== key) {
      this._dayLabels = fullDaysOfMonth(this.current, this.locale);
      this._dayLabelsKey = key;
    }
    return this._dayLabels;
  }

  getSelectedDate(date, current) {
    if (isSameMonth(date, current)) {
      return date.getDate();
    }
    return DATE_NOT_SELECTED;
  }
}
