import { KatLitFilteredElement, register } from '../../shared/base';
import { KatPageHeader } from '.';

/**
 * @component {kat-page-header-utility-links} KatPageHeaderUtilityLinks Component used to place and filter utility links within the page header component.
 */

@register(KatPageHeader.slotMapping.utilityLinks.name)
export class KatPageHeaderUtilityLinks extends KatLitFilteredElement {
  constructor() {
    super(
      KatPageHeader.slotMapping.utilityLinks.name,
      KatPageHeader.slotMapping.utilityLinks.allowed
    );
  }
}
